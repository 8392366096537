import {
  OnInit,
  Component,
  ElementRef,
  ViewChild,
  Injector,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TemplateChecklistService } from '../template-checklist-services/template-checklist.service';
// import './shapes';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { BaseComponent } from '../../../shared/components/baseComponent';
import { Pagenames } from '../../../EndPointUrls/pagenames.enum';
import { DocumentAIService } from '../../../shared/services/documentAI.service';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';
import { Subscription, fromEvent } from 'rxjs';
import { IDocument, Page } from '../../../document/interfaces/IDocument';
import { IOBTableOutput, IOBTableSettings, ObTableComponent } from 'ob-ng-library';
import { ViewTemplateComponent } from '../view-template/view-template.component';
import { MatDialog } from '@angular/material/dialog';
import { __Permissions } from 'src/app/core/permissionEnum/permissions';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-checklist-list',
  templateUrl: './checklist-list.component.html',
  styleUrls: ['./checklist-list.component.scss'],
})
export class TemplateChecklistListComponent extends BaseComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('Obtable', { static: false }) table: ObTableComponent;
  @Input()entityId:any;
  @Input() caseRecord: any;
  @Input() getStatusChecklist: any;
  create_checkList=__Permissions.createCheckListTemplateItem
  showSignature = false;
  signatureFoundSubscription: Subscription;
  backButtonSubscription: Subscription;
  tableSettings!: IOBTableSettings;
  totalRecords: number = 0;
  templateChecklist: any[] = [];
  checklistTemplateToDelete: any = null;
  checklistTemplateToView: any = null;
  deleteDialogVisible: boolean = false;
  viewDialogVisible: boolean = false;
  checklistFilters = new ChecklistGridFilters();
  constructor(
      public layoutService: LayoutService,
      private acRoute: ActivatedRoute,
      public service: DocumentAIService,
      private breadcrumbService: BreadCrumbService,
      private inject: Injector,
      public location: Location,
      public router: Router,
      public dialog: MatDialog,
      public checklistService: TemplateChecklistService,
      public authservice: AuthService,
  ) {
      super(inject, Pagenames.Templatechecklist);
  }

  ngOnInit() {
    // if(this.getStatusChecklist === undefined){
    //     this.service.casetypestatus.subscribe((data) => {
    //         this.getStatusChecklist = data
    //       })
    // }
      this.tableSettings = {
          selectable: false,
          data: this.templateChecklist,
          showDefaultFilters: undefined,
          totalRecords: this.totalRecords,
          menuButton: undefined,
          columns: [
              {
                  field: 'name',
                  header: 'Name',
                  type: 'text',
                  tooltipSize: 50,
              },
              {
                  field: 'description',
                  header: 'Description',
                  type: 'text',
                  tooltipSize: 50,
              }
          ],
          actions: [ ],
      }
      if(this.authservice.hasPermission(__Permissions.editChecklistTemplateItem) && this.getStatusChecklist != 'Published' && this.getStatusChecklist!='Retired'){
        this.tableSettings.actions.push(
            {
                icon: 'pi pi-pencil',
                function: (temp:any) => {  
                    // this.service.casetypestatus.next(this.getStatusChecklist);
                    this.router.navigate(['administration/checklist/edit', temp.id,this.entityId]);
                },
                class: 'eye',
                tooltip: 'Edit CheckList'
            },
        )
     }
     if(this.authservice.hasPermission(__Permissions.editChecklistTemplateItem) && this.getStatusChecklist === 'Published' || this.getStatusChecklist ==='Retired'){
        this.tableSettings.actions.push(
            {
                icon: 'pi pi-eye',
                function: (temp:any) => {  
                    // this.service.casetypestatus.next(this.getStatusChecklist);
                    this.router.navigate(['administration/checklist/edit', temp.id,this.entityId]);
                },
                class: 'eye',
                tooltip: 'View CheckList'
            },
        )
     }
     if(this.authservice.hasPermission(__Permissions.deleteCheckListTemplateItem) && this.getStatusChecklist != 'Published' && this.getStatusChecklist!='Retired'){
     this.tableSettings.actions.push(
    {
        icon: 'pi pi-trash',
        function: this.deleteChecklistTemp,
        class: 'eye',
        tooltip: 'Delete CheckList'
    },
)
     }
     this.breadcrumbService.setBreadcrumbs([
        { label: '', route: '/administration/casetype', subChild: { label: 'Administration', route: 'reports/reports/taskcountbystatus' }},
        { label: 'Checklist', route: '', subChild: {label: 'Case Type', route: ''} , anotherSubChild: {
            label: this.caseRecord.name + " | " + this.caseRecord.formVersion,
            route: ''
        }}
      ]);
  }
  

  loadchecklistTemplates(output: IOBTableOutput) {
    let obj = new ChecklistGridFilters();
    Object.assign(obj, this.checklistFilters);
    let queryString = this.getOdataQuery(obj, output.event);
    queryString = queryString + `&CaseRecordTypeIdFilter=${this.entityId}&Sorting=creationTime desc`
      this.service
          .getChecklistTemplate(queryString)
          .subscribe(
              (data: any) => {
                  this.templateChecklist = [];
                  for (const item of data.result.items) {
                      this.templateChecklist.push(item.checklistTemplate)
                  }
                  this.tableSettings.data = this.templateChecklist;
                  this.tableSettings.totalRecords = data.result.totalCount;
                  this.totalRecords = data.result.totalCount;
              });
  }

  exportChecklist() {
      this.service
          .exportChecklist()
          .subscribe(
              (data: any) => {
                  this.service.downloadchecklistTempFile(data.result).subscribe((result: any) => {
                      const fileName = data.result.fileName;
                      const blob = new Blob([result.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                      saveAs(blob, fileName);

                  })
              });
  }

  createChecklistTemplate(){
    // this.service.casetypestatus.next(this.getStatusChecklist);
      this.router.navigate(['administration/checklist/create',this.entityId]);
  }

  viewTemplate = (temp: any) => {
      this.checklistTemplateToView = temp;
      const dialogRef = this.dialog.open(ViewTemplateComponent, {
          data: {
              dialogViewData: this.checklistTemplateToView
          }
      });
      dialogRef.afterClosed().subscribe(
          (data: any) => {
          })
  }


  setDialogClose(value: boolean) {
      this.viewDialogVisible = false;
      this.checklistTemplateToView = null;
  }


  deleteChecklistTemp = (temp: any) => {
      this.checklistTemplateToDelete = temp;
      this.deleteDialogVisible = true
  }

  deleteconfirm() {
      this.service.deleteChecklistTemplate(this.checklistTemplateToDelete.id).subscribe(res => {
          this.toastr.success('CheckList Deleted Successfully');
          this.deleteDialogVisible = false;
          this.checklistTemplateToDelete = null;
          this.table.applyFilter("empty", "empty");
      });
  }

  hide_delete_popup() {
      this.deleteDialogVisible = false;
      this.checklistTemplateToDelete = null;
  }

  applyFilters() {
    this.table.table._filter();
}

clearFilter() {
    this.checklistFilters = new ChecklistGridFilters();
    this.applyFilters();
}


  public ngAfterViewInit(): void { }
}

export class ChecklistGridFilters {
    Filter: string;
    NameFilter: string;
    DescriptionFilter: string;
    constructor() {
        this.Filter = null;
        this.NameFilter = null;
        this.DescriptionFilter = null;
    }
}