
<!-- publish dialog -->
<p-dialog class="tablePopup" [(visible)]="openDialog"  [closable]="false" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false" [maximizable]="false">
    <ng-template pTemplate="closeicon">
      <i class="pi pi-times" (click)="closePublishModal()" style="font-size: 1.5rem; position: absolute; top: 22px; right: 22px; cursor: pointer;"></i>
    </ng-template>
    <div class="kt-portlet__body">
        <form [formGroup]="formGroup" novalidate autocomplete="off">
            <div class="modal-body">
              <div class="row" *ngIf="type == 'CreateNewVersion'">
                <div class="col-md-12">
                  <label>Want to replace existing files</label>
                  <tX-fileUploader #fileUploader (onDeleteFile)="RemoveFileFromList($event)" (onUpload)="DragDrop($event)"></tX-fileUploader>
                  <div *ngIf="submitted && isFileUploaded" class="display-block invalid-feedback">
                    <div>File is required</div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="type == 'Published'">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="Jurisdiction">{{("Published Comment")}} *</label>
                    <textarea (appTrim)="$event" type="text" id="PublishedComment" minlength="0"  maxlength="255"  class="form-control"
                    name="PublishedComment"  formControlName="PublishedComment" [ngClass]="{ 'is-invalid': submitted && controls['PublishedComment'].errors }"></textarea>
                    <div *ngIf="submitted && controls['PublishedComment'].errors" class="invalid-feedback">
                      <div *ngIf="controls['PublishedComment'].errors['required']">Published Comment is required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 20px 0px; text-align: right;">
                <div class="col-md-12 fieldButton">
                  <button id="save_form_with_version" type="submit" class="btn btn-primary blue" style="background-color: #7364DB !important;
                  border-color: #7364DB !important;" (click)="type == 'CreateNewVersion' ? createNewVersion() : saveForm()">
                    <i class="fa fa-save"></i>
                    <span *ngIf="type == 'CreateNewVersion'">Upload</span><span *ngIf="type != 'CreateNewVersion'"> &nbsp;Save</span>
                  </button>
                </div>
              </div>
              <div class="hr-sect" *ngIf="type == 'CreateNewVersion'">OR</div>
                <div class="row" style="margin-top:20px ;" *ngIf="type == 'CreateNewVersion'">
                  <div class="col-md-12" style="text-align: center;">
                    <button id="cancal_new_form" type="button" class="btn btn-light" (click)="cancel()">
                      <span *ngIf="type == 'CreateNewVersion'">No, Continue with existing file</span><span *ngIf="type != 'CreateNewVersion'">Cancel</span>
                    </button>
                  </div>
                </div>
          </div>
        </form>
      </div>
</p-dialog>
