import { Injectable, Injector, ElementRef, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

import { DocumentAIService } from 'src/app/shared/services/documentAI.service';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TemplateChecklistService extends BaseService {
    override get apiUrl(): string {
        return `${environment.API_Document}`;
    }
    constructor(inject: Injector, public service: DocumentAIService) {
        super(inject);
    }
}
