import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPositiveInteger]'
})
export class PositiveIntegerDirective {

  constructor() { }
  @HostListener('input', ['$event']) onInput(event: KeyboardEvent|any) {
    console.log(event);
    
    const input = event.target as HTMLInputElement;
    const inputValue = event.data;

    // Remove any non-integer characters
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    // Update the input value with the sanitized value
    if (inputValue !== sanitizedValue) {
      input.value = sanitizedValue;
      input.dispatchEvent(new Event('input'));
    }
    // if (!/^\d+$/.test(inputValue)) {
    //   // Prevent the default behavior (typing) if the input value is not an integer
    //   event.preventDefault();
    // }
  }
}
