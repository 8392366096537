import { Component, OnInit, Input, Output, Injector, EventEmitter, ViewChild } from '@angular/core';
// import { AppComponentBase } from '@shared/common/app-component-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { FieldsComponent } from '../../canvas-fields/fields/fields.component';
import { NgOption } from '@ng-select/ng-select';
import { UploadOutput } from 'ngx-uploader';
import { BaseComponent } from 'src/app/shared/components/baseComponent';
import { FileUploaderComponent } from 'src/app/shared/components/fileUploader/fileUploader.component';
// import { v4 as uuidv4 } from 'uuid';
// import * as moment from 'moment';

@Component({
  selector: 'app-publish-comments',
  templateUrl: './publish-comments.component.html',
  styleUrls: ['./publish-comments.component.css']
})
export class PublishCommentsComponent extends BaseComponent implements OnInit {
  isFileUploaded: boolean;
  formGroup: FormGroup
  templatelibrary: NgOption[];
  buttonLabel: string;
  submitted: boolean = false;
  type: string = '';
  @Input('openDialog') openDialog: boolean;
  @Input('publishDialogData') publishDialogData: any;
  @Output() setDialogClose = new EventEmitter<boolean>();
  @Output() saveDialog = new EventEmitter<any>();
  // convenience getter for easy access to form fields
  get controls() { return this.formGroup.controls; }
  @ViewChild('fileUploader', { static: false }) fileuploader: FileUploaderComponent;
  constructor(injector: Injector,
    private formBuilder: FormBuilder) {
    super(injector, '');
  }

  ngOnInit() {
    this.type = this.publishDialogData.type;
    this.buildFormBuilderControl();
  }

  cancel() {
    if (this.publishDialogData.type == "CreateNewVersion") {
      this.saveDialog.emit({ event: 'No' });
      this.closePublishModal();
    } else {
      this.saveDialog.emit({ event: 'cancel' });
      this.closePublishModal();
    }
  }

  saveForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formGroup.invalid) {
      return;
    }
    this.saveDialog.emit({ event: 'Save', data: this.formGroup.controls['PublishedComment'].value });
    this.closePublishModal();
  }

  onNoClick() {
    // this.dialogRef.close({ event: 'Close'});
  }

  DragDrop(output:UploadOutput) {

    if (this.fileuploader.files.length >= 5 ) {
      this.toastr.error("Maximum number of files to be processed exceeded!")
    }
    else{
      if (output.file.nativeFile) {
          this.fileuploader.files.push(output.file.nativeFile);
        } else {
          this.toastr.error('Total file size exceeds the maximum limit of 25 MB.')
        }
    }
  }

  RemoveFileFromList(event:any){
  }

  createNewVersion() {
    this.submitted = true;

    this.isFileUploaded = false;
    if (this.fileuploader && this.fileuploader.files.length == 0) {
      this.isFileUploaded = true;
      return;
    }

    var formData = new FormData();
    if (this.publishDialogData.type == "CreateNewVersion") {
      var files = this.fileuploader.files;
      if (files.length > 0) {
        formData.append('file', files[0], files[0].name);
      };
      this.saveDialog.emit({ event: 'CreateNewVersion', data: formData });
      this.closePublishModal();
    }
  }

  closePublishModal() {
    this.openDialog = false;
    this.setDialogClose.emit(false);
  }

  private buildFormBuilderControl() {
    this.formGroup = this.formBuilder.group({
      PublishedComment: [{ value: '', disabled: false }, Validators.required]
    });
  }

}
