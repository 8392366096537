<div class="create-role-model">
    <h3 mat-dialog-title>
        {{data ? 'Edit Role:' + data.displayName : 'Create Role' }}
    </h3>
    <div mat-dialog-content>
        <div class="card">
            <form #roleForm="ngForm" novalidate (ngSubmit)="save(roleForm)" autocomplete="new-password">
                <div class="form-group">
                    <label>Name *</label>
                    <input id="name" #name="ngModel" class="form-control" type="text"
                        name="name" [(ngModel)]="data.name" required maxlength="64">
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-light-primary font-weight-bold"
                        (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-primary font-weight-bold"
                         ><i class="fa fa-save"></i> <span>
                            Save
                         </span></button>
                </div>
            </form>
        </div>
        <!--
        <p-tabView>
            <p-tabPanel header="Role">
                <div class="card">
                    <h2>Role</h2>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Permissions">
                <div class="card">
                    <h2>Permissions</h2>
                    <permission-tree #permissionTree></permission-tree>
                </div>
            </p-tabPanel>
        </p-tabView>
     -->
    </div>
</div>