import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppMenuSyncService {

  private syncSubject = new BehaviorSubject<any>(null);  // For synchronization
  
  syncAppMenu(data: any) {
    this.syncSubject.next(data);  // Update sync data
  }

  getSyncUpdates() {
    return this.syncSubject.asObservable();  // Return an observable for updates
  }
  
}
