// import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './interceptor/appinterceptor';
// import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentUtcDateAdapter } from './services/moment-utc-date-adopter.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { AppErrorHandler } from './AppErrorHandler';
// import { UrlSerializer } from '@angular/router';
// import { CustomUrlSerializer } from './customUrlSerializer/customUrlSerializer';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'll',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}
@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    ProgressSpinnerModule
  ],
  exports:[
    ProgressSpinnerModule,
    MatProgressSpinnerModule
  ],
  declarations: [],
  providers:[
     LoaderService,
     ToastrService,
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ]
})
export class CoreModule { }
