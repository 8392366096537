import { Component, OnInit, Input, Output, Injector, Inject, ChangeDetectorRef } from '@angular/core';;
import { BaseComponent } from 'src/app/shared/components/baseComponent';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';
import { DocumentAIService } from 'src/app/shared/services/documentAI.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFlatPermissionDto, PermissionTreeEditModel } from '../../interfaces/user.interface';
import { ArrayToTreeConverterService } from '../../services/array-to-tree-converter.service';
import { TreeDataHelperService } from '../../services/tree-data-helper.service';

@Component({
  selector: 'app-permission-tree',
  templateUrl: './permission-tree.component.html',
  styleUrls: ['./permission-tree.component.scss']
})
export class PermissionTreeComponent extends BaseComponent implements OnInit {

  @Input() loaded: boolean;
  @Input() singleSelect: boolean;


  treeData: any;
  filter = '';

  files!: TreeNode[];
  userId: number;
  selectedPermissionListSearch:any;
  selectedFiles!: TreeNode[];

  selectedPermissions: TreeNode[] = [];
  NumberOfFilteredPermission = 0;
  constructor(injector: Injector,
    public service: DocumentAIService,
    private cdr: ChangeDetectorRef,
    public treeDataHelperService: TreeDataHelperService,
    public arrayToTreeConverterService: ArrayToTreeConverterService,
    public dialogRef: MatDialogRef<PermissionTreeComponent>, 
    @Inject(MAT_DIALOG_DATA) public data : {
      user:any,selectedPermissionList:any
    }) {
    super(injector, '');
    this.userId = data.user != undefined && data.user != 'search' ?  data.user?.id : 0;
    this.selectedPermissionListSearch = data.selectedPermissionList
  }

  ngOnInit(): void {
    if(this.userId == 0) {
      this.loadAllPermissions();
    } else {
      this.loadAllPermissionsToFilterTree(this.userId);
    }
  }


  private loadAllPermissions() {
    
    let treeModel: IFlatPermissionDto[] = [];
    this.service.getPermissions().subscribe(data => {
      if (data.result.items) {
        data.result.items.forEach((item: any) => {
          treeModel.push({
            name: item.name,
            description: item.description,
            displayName: item.displayName,
            isGrantedByDefault: item.isGrantedByDefault,
            parentName: item.parentName
          });
        });
        this.setTreeData(treeModel);
        this.setSelectedNodes(this.selectedPermissionListSearch != undefined && this.selectedPermissionListSearch.length > 0 ?  this.selectedPermissionListSearch : data.result.grantedPermissionNames)
      }
    });
  }

  private loadAllPermissionsToFilterTree(userId:number) {
    
    let treeModel: IFlatPermissionDto[] = [];
    this.service.GetUserPermissionsForEdit(userId).subscribe(data => {
      if (data.result.permissions) {
        data.result.permissions.forEach((item: any) => {
          treeModel.push({
            name: item.name,
            description: item.description,
            displayName: item.displayName,
            isGrantedByDefault: item.isGrantedByDefault,
            parentName: item.parentName
          });
        });
        this.setTreeData(treeModel)
        this.setSelectedNodes(this.selectedPermissionListSearch != undefined && this.selectedPermissionListSearch.length > 0 ?  this.selectedPermissionListSearch : data.result.grantedPermissionNames)
      }
    });
  }

  setTreeData(permissions: IFlatPermissionDto[]) {
    this.treeData = this.arrayToTreeConverterService.createTree(permissions, 'parentName', 'name', null, 'children',
        [{
            target: 'label',
            source: 'displayName'
        }, 
        {
            target: 'expandedIcon',
            value: 'fa fa-folder-open m--font-warning'
        },
        {
            target: 'collapsedIcon',
            value: 'fa fa-folder m--font-warning'
        },
        {
            target: 'expanded',
            value: true
        },
        // {
        //     target: 'selectable',
        //     targetFunction(item:any) {
        //         return item.inputType.name === 'CHECKBOX';
        //     }
        // }
      ]);
}

setSelectedNodes(grantedPermissionNames: string[]) {
  this.selectedPermissions = [];
  _.forEach(grantedPermissionNames, permission => {
      let item = this.treeDataHelperService.findNode(this.treeData, { data: { name: permission } });
      if (item) {
          this.selectedPermissions.push(item);
      }
  });
}

getGrantedPermissionNames(): string[] {
  if (!this.selectedPermissions || !this.selectedPermissions.length) {
      return [];
  }

  let permissionNames = [];

  for (let i = 0; i < this.selectedPermissions.length; i++) {
      permissionNames.push(this.selectedPermissions[i].data.name);
  }

  return permissionNames;
}

nodeSelect(event:any) {
  console.log(event);
  
  if (this.singleSelect) {
      this.selectedPermissions = [event.node];
      return;
  }

  let parentNode = this.treeDataHelperService.findParent(this.treeData, { data: { name: event.node.data.name } });

  while (parentNode != null) {
      this.selectedPermissions.push(parentNode);
      parentNode = this.treeDataHelperService.findParent(this.treeData, { data: { name: parentNode.data.name } });
  }
  this.cdr.detectChanges();
}

cancel = () => {
  this.dialogRef.close(null);
}

onNodeUnselect(event:any) {
  
  let childrenNodes = this.treeDataHelperService.findChildren(this.treeData, { data: { name: event.node.data.name } });
  childrenNodes.push(event.node.data.name);
  _.remove(this.selectedPermissions, x => childrenNodes.indexOf(x.data.name) !== -1);
  this.cdr.detectChanges();
}

filterPermissions(event:any): void {
  
  this.filterPermission(this.treeData, this.filter);
}

filterPermission(nodes:any, filterText:any): any {
  _.forEach(nodes, (node: any) => {
      if (node.data.displayName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) {
          node.styleClass =
              this.showParentNodes(node);
      } else {
          node.styleClass = 'hidden-tree-node';
      }

      if (node.children) {
          this.filterPermission(node.children, filterText);
      }
  });
}

showParentNodes(node:any): void {
  if (!node.parent) {
      return;
  }

  node.parent.styleClass = '';
  this.showParentNodes(node.parent);
}

  selectPermissions(){
    let selections = this.getSelectedPermissions();
    this.NumberOfFilteredPermission = selections.length;
    
    if(this.data) {
      this.dialogRef.close({selections: selections, numberOfFilteredPermission: this.NumberOfFilteredPermission, isUpdate: true});
    } else {
      this.dialogRef.close({selections: selections, numberOfFilteredPermission: this.NumberOfFilteredPermission, isUpdate: false});
    }
  }

  
  getSelectedPermissions(): string[] {
    let permissions = this.getGrantedPermissionNames()
        .filter((test, index, array) =>
            index === array.findIndex((findTest) =>
                findTest === test
            )
        );

    return permissions;
}

  closePopup() {
    this.dialogRef.close();
  }

//   getFiles() {
//     return Promise.resolve(this.getTreeNodesData());
// }

// getTreeNodesData() {
//   return [
//       {
//           key: '0',
//           label: 'Documents',
//           data: 'Documents Folder',
//           icon: 'pi pi-fw pi-inbox',
//           children: [
//               {
//                   key: '0-0',
//                   label: 'Work',
//                   data: 'Work Folder',
//                   icon: 'pi pi-fw pi-cog',
//                   children: [
//                       { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
//                       { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
//                   ]
//               },
//               {
//                   key: '0-1',
//                   label: 'Home',
//                   data: 'Home Folder',
//                   icon: 'pi pi-fw pi-home',
//                   children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
//               }
//           ]
//       },
//       {
//           key: '1',
//           label: 'Events',
//           data: 'Events Folder',
//           icon: 'pi pi-fw pi-calendar',
//           children: [
//               { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
//               { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
//               { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
//           ]
//       },
//       {
//           key: '2',
//           label: 'Movies',
//           data: 'Movies Folder',
//           icon: 'pi pi-fw pi-star-fill',
//           children: [
//               {
//                   key: '2-0',
//                   icon: 'pi pi-fw pi-star-fill',
//                   label: 'Al Pacino',
//                   data: 'Pacino Movies',
//                   children: [
//                       { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
//                       { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
//                   ]
//               },
//               {
//                   key: '2-1',
//                   label: 'Robert De Niro',
//                   icon: 'pi pi-fw pi-star-fill',
//                   data: 'De Niro Movies',
//                   children: [
//                       { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
//                       { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
//                   ]
//               }
//           ]
//       }
//   ];
// }

}
