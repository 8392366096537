<div class="row">
    <div class="col-md-12">
        <div (textSelect)="renderRectangles($event)" class="container">
            <pre style="font-size: 10px;text-align: left;" [style.font-size]="fontSize + 'px'" class="line-Space" id="annotation" #someVar
                [innerHTML]="text"></pre>
            <div *ngIf="hostRectangle" class="indicator" [style.left.px]="hostRectangle.left + hostRectangle.width"
                [style.top.px]="hostRectangle.top" [style.width.px]="hostRectangle.width">
            </div>
        </div>
    </div>
</div>
