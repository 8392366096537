import { Component, ElementRef, EventEmitter, Inject, Injector, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/baseComponent';
import { PermissionTreeComponent } from '../../user/components/permission-tree/permission-tree.component';
import { DocumentAIService } from 'src/app/shared/services/documentAI.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'createOrEditRoleModal',
    templateUrl: './create-or-edit-role-modal.component.html',
    styleUrls: ['./create-or-edit-role-modal.component.scss']
})
export class CreateOrEditRoleModalComponent extends BaseComponent {

    @ViewChild('permissionTree') permissionTree: PermissionTreeComponent;

    // @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    role: any;
    constructor(
        injector: Injector,
        public service: DocumentAIService,
        public dialogRef: MatDialogRef<CreateOrEditRoleModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(injector, '');
    }

    ngOnInit(): void {


        //get Role by Id
        // this.service.getRoleForEdit(this.data.id).subscribe((result:any) => {
        //     this.role = result.role;
        // });
        
    }

    save(roleForm:NgForm): void {
   
        this.saving = true;
        this.data.name = roleForm.value.name;
        this.service.updateRole(this.data)
            .pipe(finalize(() => this.saving = false))
            .subscribe(() => {
                this.dialogRef.close();
        });
    }


    close(): void {
       this.dialogRef.close()
    }
}
