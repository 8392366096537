<!-- <div class="container mainHeaderTop">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <h1>Try extracting data out of your documents with high accuracy using OpenBots Document AI platform.</h1>
    </div>
    <div *ngIf="urlPresent !== ''" class="col-md-12 col-sm-12 col-xs-12 text-center mainVideo">
      <iframe width="560" height="315" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
</div> -->
<div class="container mainHeader">

  <div *ngIf="templateLibraries.length > 0" class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <div *ngFor="let item of templateLibraries" class="cardBox cardBox-inline" (click)="navigateToIndustry(item.templateLibrary)">
        <div class="cardBoxBrdr">
          <img src="../../../assets/layout/images/{{item.templateLibrary.icon!== null? item.templateLibrary.icon: 'defaultIcon.png'}}" />
          <h2> {{item.templateLibrary.name}} </h2>
        </div>
      </div>
    </div>
  </div>
</div>
