
import { Component, ElementRef } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { MenuItem } from 'primeng/api';
import { AuthService } from '../core/services/auth.service';
import { IUserRole } from '../interfaces/IUser';
import { Router } from '@angular/router';
import { __Permissions } from '../core/permissionEnum/permissions';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
    styles: [
        `
        ::ng-deep .dock-window ul{
            padding-left: 0;
            margin: 0;
            padding-top: 0px;
            background-color: #f5f5f5;
        }
        ::ng-deep .dock-window ul li{
            display: inline-block;
            vertical-align: middle;
            padding: 10px;
        }
        ::ng-deep .layout-static-inactive .dock-window ul li{
            display: block;
            text-align: center;
        }
        ::ng-deep .dock-window ul li img{
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            cursor: pointer;
        }
        ::ng-deep .dock-window ul li.activeIcons img {
            filter: initial;
        }
        ::ng-deep .dock-window ul li.activeIcons {
            background-color: #fff;
        }
        
        `
    ]
})
export class AppSidebarComponent {
    items: MenuItem[] | undefined;
    constructor(public layoutService: LayoutService,public authService:AuthService, public el: ElementRef,
        private router: Router) { }
    ngOnInit(){
        // console.log(this.authService.selectedUserRole.roleId, "user");
        
        // if (this.authService.selectedUserRole.roleId) {
        //     console.log("selected User Role");
           
        // } else {
        //     console.log("not selected User Role");
        //     if(this.authService.hasPermission('View Functions') || this.authService.hasPermission("Call Functions")) {
        //         this.authService.selectedUserRole.roleId = 3,
        //         this.authService.selectedUserRole.roleName = "developer"
        //         this.router.navigate(['/functions']).then( ()=>
        //             this.authService.loadApp(true)
        //         )
        //     } else if (this.authService.hasPermission(__Permissions.documentList)) {
        //         this.authService.selectedUserRole.roleId = 1,
        //         this.authService.selectedUserRole.roleName = "admin"
        //         this.router.navigate(['/administration/templateLibraries']).then(
        //             ()=> {
        //                 this.authService.loadApp(true)
        //             }
        //         )
        //     } else {
        //         this.authService.selectedUserRole.roleId = 2,
        //         this.authService.selectedUserRole.roleName = "Contributor"
        //     }
        // }
        
    }
    
}