import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, NgZone, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppMonitoringService } from './services/appMonitoring.service';
import { AuthService } from './services/auth.service';
@Injectable()
export class AppErrorHandler extends ErrorHandler {
    constructor(
        @Inject(Injector) private injector: Injector,
        private monitoringService: AppMonitoringService,
        private zone: NgZone
    ) {
        super();
    }
    // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
    private get message(): ToastrService {
        return this.injector.get(ToastrService);
    }
    private get authService(): AuthService {
        return this.injector.get(AuthService);
    }
    public override handleError(error: HttpErrorResponse): void {
        let errormessage = '';
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            errormessage = error?.status + error.error;
           this.zone.run(() => this.message.error(errormessage));
            this.monitoringService.logException(error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            errormessage = error?.error?.error?.message;
            if (errormessage) {
                if(errormessage.includes("Exception of type 'Abp.Authorization.AbpAuthorizationException' was thrown"))
                {
                    errormessage="You don't have permission to access this page."
                }
                this.zone.run(() => this.message.error(errormessage));
                this.monitoringService.logException(error);
            }
        }

        // Return an observable with a user-facing error message.
        // super.handleError(error);
    }
}
