import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-input',
  templateUrl: './formly-case-input.component.html',
  styleUrls: ['./formly-case-input.component.scss']
})
export class FormlyCaseInputComponent extends FieldType<FieldTypeConfig> implements OnInit{

  constructor() {
    super();
   }

  ngOnInit() {
    console.log(this.formControl);
    
  }

}
