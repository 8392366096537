import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-textarea',
  template: `<div>
  <label> {{props.label}} </label>
  <textarea [formControl]="formControl" [formlyAttributes]="field"></textarea></div>
  `
})
export class FormlyTextareaComponent extends FieldType<FieldTypeConfig> implements OnInit {
  // label: string ;
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
