<!-- <div class="dock-window">
    <ul>
        <li *ngIf="this.authService.selectedUserRole.roleId == 1 || this.authService.selectedUserRole.roleId == 2" 
        [ngClass]="{activeIcons: this.authService.selectedUserRole.roleId == 2}" (click)="SelectRole({roleId:2, roleName:'Contributor'})" pTooltip="Contributor" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        <li *ngIf="this.authService.selectedUserRole.roleId == 1" [ngClass]="{activeIcons: this.authService.selectedUserRole.roleId == 1}" (click)="SelectRole({roleId:1, roleName:'admin'})" pTooltip="Administrator" >
        <img src="../../assets/layout/images/Admin.svg" /></li>
        <li *ngIf="this.authService.selectedUserRole.roleId == 1 || this.authService.selectedUserRole.roleId == 3"
        [ngClass]="{activeIcons: this.authService.selectedUserRole.roleId == 3}" (click)="SelectRole({roleId:3, roleName:'developer'})" pTooltip="Developer" >
        <img src="../../assets/layout/images/Developer.svg" /></li>
    </ul>
</div> -->
 <app-menu></app-menu>
 