import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class TreeDataHelperService {
    constructor(){
    }
    findNode(data:any, selector:any): any {
        let nodes = _.filter(data, selector);
        if (nodes && nodes.length === 1) {
            return nodes[0];
        }

        let foundNode:any = null;

        _.forEach(data, (d:any) => {
            if (foundNode ==  null) {
                foundNode = this.findNode(d.children, selector);
            }
        });

        return foundNode;
    }

    findParent(data:any, nodeSelector:any) {
        let node = this.findNode(data, nodeSelector);
        if (!node) {
            return null;
        }

        return node.parent;
    }

    findChildren(data:any, selector:any) {
        let traverseChildren = function (node:any) {
            let names:any[] = [];
            if (node.children) {
                _.forEach(node.children, (c:any) => {
                    names.push(c?.data.name);
                    names = names.concat(traverseChildren(c));
                });
            }
            return names;
        };

        let foundNode = this.findNode(data, selector);
        if (foundNode) {
            return traverseChildren(foundNode);
        } else {
            return [];
        }
    }
}
