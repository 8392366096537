<!-- view dialog -->
<div class="Templatedetails">
    <h3>Details</h3>
    <div class="closeicon">
        <i class="pi pi-times" (click)="closePublishModal()"
            style="font-size: 1.5rem; position: absolute; top: 22px; right: 22px; cursor: pointer;"></i>
    </div>
    <div class="kt-portlet__body">
        <div class="row">
            <div class="col-6 mb-3">
                <h5>Name :</h5>
                <p>
                    {{ data.dialogViewData.name }}
                </p>
            </div>
            <div class="col-6 mb-3">
                <h5>Description :</h5>
                <p>
                    {{ data.dialogViewData?.description }}
                </p>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" pButton icon="pi pi-times" (click)="closePublishModal()"
                    class="p-button-secondary">Close</button>
            </div>
        </div> -->
    </div>
</div>