export enum CASTSTATUS {
    "Awaiting Verification"='Ready',
    "Created" = 'Queued',
    "In Progress"='Processing'
}
export enum STATUS {
    AwaitingVerification='Awaiting Verification',
    Created = 'Created',
    InProgress='In Progress'
}

