import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {
    constructor(private authServie :AuthService,public toast:ToastrService){}
  canActivate( route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        if(this.authServie.hasPermission(route.data['permission'],route.data['checkBillingStatus']!=undefined?route.data['checkBillingStatus']:false))
        {
             return true;
        }
         else
         {
              this.toast.error("","You Don't Have Permission to Access this Page");
              return false;
         }

  }
}
