import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog-popup',
  templateUrl: './confirmation-dialog-popup.component.html',
  styleUrls: ['./confirmation-dialog-popup.component.scss']
})
export class ConfirmationDialogPopupComponent {
  @Input() dialogVisible: boolean;
  @Input() confirmationMessage: string;
  @Input() confirmationHeader: string ;
  @Output() yes = new EventEmitter<void>();
  @Output() No = new EventEmitter<void>();
  @Output() hide = new EventEmitter<void>();

  constructor(
  ) { }

  ngOnInit() {
  }
  confirmDelete() {
    this.yes.emit();
  }

  hideDeleteDialog() {
    this.No.emit();
  }

  onDialogHide() {
    this.hide.emit();
    this.dialogVisible=false;
    // Handle dialog hide event if needed
  }
}
