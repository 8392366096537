import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-file-input',
  template: ` <input class="playgroundInput" (change)="fileUpload($event)" type="file" [formlyAttributes]="field" /> `,
})
export class FormlyFileInputComponent extends FieldType<FieldTypeConfig> implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
  fileUpload(event:any) {
    // let file: File = event.target.files[0]
    // this.formControl.setValue(file)
    if (event.target.files && event.target.files.length > 0) {
      const file = (event.target.files[0] as File);
      this.formControl.setValue(file);
    }
  }
}
