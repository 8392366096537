import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox',
  templateUrl: './formly-checkbox.component.html',
  styleUrls: ['./formly-checkbox.component.scss']
})
export class FormlyCheckboxComponent extends FieldType<FieldTypeConfig> implements OnInit {
  trueCases = ['true', 'selected', ':selected:' ]
  constructor() {
    super();
   }
   
  ngOnInit() {
    // const control = this.form.get(this.field.name);
    // console.log(this.field, this.formControl.value)
    if (typeof this.formControl.value == "string") {
      if (this.trueCases.includes(this.formControl.value)) {
        this.formControl.setValue(true)
      } else {
        this.formControl.setValue(false)
      }
    } 
    
   
  }
  // change(){
  //     console.log(this.model)
  // }
}
