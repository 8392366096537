import { Breadcrumb } from './breadcrumb/breadcrumb.component'
import { AccountUsageComponent } from './../reports/components/accountUsage/accountUsage.component'
import { Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { Component } from '@angular/core'
import { AuthService } from '../core/services/auth.service'
import { Router } from '@angular/router'
import { __Permissions } from '../core/permissionEnum/permissions'
import { LighthouseService } from '../shared/services/lighthouse.service'
import { FunctionsComponent } from '../functions/functions.component'
import { MenuItem } from 'primeng/api'
import { BehaviorSubject, map, Subject } from 'rxjs'
import { Lighthouse_EndPoint } from '../EndPointUrls/apiEndPoints.enum'
import { ILightHouseMenuItem } from '../document/interfaces/ILightHouseMenuItem'
import { data } from 'jquery'
import { BreadCrumbService } from '../core/services/breadcrumb.service'
import { DocumentAIService } from '../shared/services/documentAI.service'
import { HumanTask } from '../document/interfaces/HumanTask'
import { AppMenuSyncService } from '../shared/services/app-menu-sync.service'

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styles: [``],
})
export class AppMenuComponent implements OnInit, OnDestroy {
    allFunctionList: any[]
    caseRecordTypes: any[] = []
    documentRecord: any[] = []
    allFunctionListDeail: any[]
    model: any[] = []
    ContributorMenu: any[] = []
    DocumentsMenu: any[] = []
    AdminMenu: any[] = []
    DeveloperMenu: any[] = []
    hasContributorPermission: boolean = false
    selectedMenu: string = ''
    hasAdminPermission: boolean = false
    hasDeveloperPermission: boolean = false
    showRefreshBtn: boolean = false;
    totalFunctions: any
    documentQuery: any
    DocumentsList: HumanTask[] = []
    documentTotalCount: number = 0
    showDocumentTaskList: boolean = false
    skipCount: any // Start at 0 or any initial value
    maxResultCount = 30

    constructor(
        public authService: AuthService,
        private router: Router,
        public service: LighthouseService,
        private docAiservice: DocumentAIService,
        private appMenuSyncService: AppMenuSyncService,
        @Inject(BreadCrumbService) private breadcrumbService: BreadCrumbService
    ) {}

    contributorPermissions = [
        __Permissions.document_create,
        __Permissions.documentEdit,
        __Permissions.document_delete,
        __Permissions.documentList,
        __Permissions.select_Case,
    ]
    documenttab = [__Permissions.select_Case]
    adminPermissions = [
        __Permissions.templateCreate,
        __Permissions.createPipeline,
        __Permissions.reports,
        // __Permissions.taskQueue,
        __Permissions.createTaskQueue,
        __Permissions.OrganizationUnits,
        __Permissions.users,
        __Permissions.events_create,
        __Permissions.CaseRecordTypesCreate,
        __Permissions.Configration,
    ]
    developerPermissions = [__Permissions.functionCall]

    parseQueryParams(url: string): { [key: string]: any } {
        let queryString: any = url.split('?query=?')[1]
        // console.log(queryString)
        return queryString
    }

    ngOnDestroy(): void {
        this.DocumentsList = []
    }

    ngOnInit() {
        this.appMenuSyncService.getSyncUpdates().subscribe((data) => {
            if (data) {
                if (localStorage.getItem('filter') != null || undefined) {
                    // console.log(localStorage.getItem('filter'), 'ng')
                    let params: any = this.parseQueryParams(
                        localStorage.getItem('filter')
                    )
                    this.documentQuery = `?${params}`
                    // console.log(params, 'olllmenu')
                }
                this.showDocumentTaskList = data.showMenu
                this.SelectRole(data.name)
                this.syncAppMenu(data) // Perform synchronization
            }
        })

        if (__Permissions.select_Case) {
            this.getCaseTypes()
        }

        this.checkForSideNavPermission()
        this.ContributorMenu = [
            {
                items: [
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.document_create,
                            false
                        ),
                        label: 'Upload Documents',
                        routerLink: ['/document/create'],
                        class: 'upload-doc',
                        data: { breadcrumb: 'Upload Documents' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.documentList,
                            false
                        ),
                        label: 'Processing Status',
                        routerLink: ['/document/Processing'],
                        class: 'upload-doc process-list',
                        data: { breadcrumb: 'Processing Status' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.documentList,
                            false
                        ),
                        label: 'Documents',
                        routerLink: ['/document/list'],
                        class: 'upload-doc doc-list',
                        data: { breadcrumb: 'Documents' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.batchCreate,
                            false
                        ),
                        label: 'Batches',
                        routerLink: ['/batch/list'],
                        class: 'upload-doc batches',
                        data: { breadcrumb: 'Batches' },
                    },

                    {
                        visible: this.authService.hasPermission(
                            __Permissions.select_Case,
                            false
                        ),
                        label: 'Case',
                        class: 'case-list',
                        items: [],
                        menuType: 'case-types',
                        // command: () =>  this.getCaseTypes(),
                    },
                ],
            },
        ]
        this.DocumentsMenu = [
            {
                items: [
                    {
                        visible: this.showDocumentTaskList,
                        label: 'Document List',
                        class: 'upload-doc doc-list',
                        // items: [],
                        menuType: 'case-types',
                        // command: () =>  this.getDocumentList(),
                    },
                ],
            },
        ]
        this.AdminMenu = [
            {
                icon: 'pi pi-user',
                label: 'Administration',
                data: { breadcrumb: 'Administration' },
                items: [
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.reports,
                            false
                        ),
                        label: 'Reports',
                        class: 'upload-doc reportIcon',
                        routerLink: ['/reports'],
                        data: { breadcrumb: 'Reports' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.templateCreate,
                            false
                        ),
                        label: 'Templates',
                        class: 'upload-doc templateIcon',
                        routerLink: ['/administration/template'],
                        data: { breadcrumb: 'Templates' },
                    },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.createTemplateLibrary,
                    //         false
                    //     ),
                    //     label: 'Template Libraries',
                    //     class: 'upload-doc templateIcon',
                    //     routerLink: ['/administration/libraries'],
                    //     data: { breadcrumb: 'Template Libraries' }
                    // },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.users,
                            false
                        ),
                        label: 'Users',
                        class: 'upload-doc userIcon',
                        routerLink: ['/administration/user'],
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.createPipeline,
                            false
                        ),
                        label: 'Pipelines',
                        class: 'upload-doc pipelineIcon',
                        routerLink: ['/administration/pipelines'],
                        data: { breadcrumb: 'Pipelines' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.createTaskQueue,
                            false
                        ),
                        label: 'Task Queues',
                        class: 'upload-doc queIcon',
                        routerLink: ['/administration/taskQueue'],
                        data: { breadcrumb: 'Task Queues' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.CaseRecordTypesCreate,
                            false
                        ),
                        label: 'Case Types',
                        class: 'upload-doc caseRecordIcon',
                        routerLink: ['/administration/casetype'],
                        data: { breadcrumb: 'Case Type' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.OrganizationUnits,
                            false
                        ),
                        label: 'Teams',
                        class: 'upload-doc teamsIcon',
                        routerLink: ['/administration/team'],
                        data: { breadcrumb: 'Teams' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.events_create,
                            false
                        ),
                        label: 'Events',
                        class: 'upload-doc eventIcon',
                        routerLink: ['/administration/event'],
                        data: { breadcrumb: 'Teams' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.Configration,
                            false
                        ),
                        label: 'Configuration',
                        class: 'upload-doc configIcon',
                        routerLink: ['/administration/configuration'],
                        data: { breadcrumb: 'Configuration' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.DocumentTags,
                            false
                        ),
                        label: 'Tags',
                        class: 'upload-doc tagIcon',
                        routerLink: ['/administration/tagsList'],
                        data: { breadcrumb: 'Tags' },
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.Configration , false
                        ),
                        label: 'Web Hooks',
                        class: 'upload-doc webhooksIcon',
                        routerLink: ['/administration/webHooks'],
                        data: { breadcrumb: 'webHooks' }
                    },
                    // {
                    //     //TODO change to  account balance permissions
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.CaseRecords,
                    //         false
                    //     ),
                    //     label: 'Case Records',
                    //     class: 'upload-doc caseRecordIcon',
                    //     routerLink: ['/administration/casefields'],
                    // },
                    // {
                    //     //TODO change to  account balance permissions
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.caseRecordFieldsCustomizationses,
                    //         false
                    //     ),
                    //     label: 'Custom Case Records Fields',
                    //     class: 'upload-doc customFieldIcon',
                    //     routerLink: ['/administration/customcasefields'],
                    // },
                    // {

                    //     visible:this.authService.hasPermission(__Permissions.BalanceCredits,false),
                    //     label: 'Account Balance',
                    //     icon: 'pi pi-folder',
                    //     routerLink: ['/administration/balancecredits'],
                    // },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.createCheckListTemplateItem,
                    //         false
                    //     ),
                    //     label: 'Checklist Template',
                    //     class: 'upload-doc checklistIcon',
                    //     routerLink: ['/administration/checklist'],
                    // },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.createRoles,
                    //         false
                    //     ),
                    //     label: 'Roles',
                    //     class: 'upload-doc roleIcon',
                    //     routerLink: ['/administration/roles'],
                    // },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.ChecklistTemplateItems,
                    //         false
                    //     ),
                    //     label: 'Audit Logs',
                    //     class: 'upload-doc checklistIcon',
                    //     routerLink: ['/administration/audit-logs'],
                    // },
                ],
            },
        ]
        // this.model = this.AdminMenu
        if (this.hasContributorPermission) {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Contributor', route: '' },
            ])

            this.selectedMenu = 'contributor'
            this.model = this.ContributorMenu
            this.getCaseTypes()
        } else if (this.showDocumentTaskList) {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Documents', route: '' },
            ])

            this.selectedMenu = 'Documents'
            this.model = this.DocumentsMenu
            this.getDocumentList()
        } else if (this.hasAdminPermission) {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Administration', route: '' },
            ])
            this.selectedMenu = 'admin'
            this.model = this.AdminMenu
        } else {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Developer', route: '' },
            ])
            this.selectedMenu = 'developer'
            this.model = this.DeveloperMenu
        }

        this.selectedActivetab()
    }

    syncAppMenu(data: any) {
        // console.log('AppMenuComponent synchronized with:', data)
        // Logic to sync component (update menu, refresh, etc.)
    }

    getCaseTypes() {
        let url = `?StatusFilter=Published&MaxResultCount=100`
        this.docAiservice.GetAllCaseRecordsType(url).subscribe((data: any) => {
            const lastMenu = this.ContributorMenu[0].items.length - 1
            this.ContributorMenu[0].items[lastMenu].items = []
            const caseRecordTypes = data.result.items?.map(
                (a: any) => a.caseRecordType
            )
            
            for (const caseType of caseRecordTypes) {
                this.ContributorMenu[0].items[lastMenu].items.push({
                    label: caseType.name,
                    menuType: 'case-types',
                    class: 'upload-doc caseRecordIcon',
                    command: () => this.gotoCaseType(caseType),
                })


            }
            this.showRefreshBtn = true;
            this.caseRecordTypes = caseRecordTypes
            this.ContributorMenu[0].items[lastMenu].visible =
                this.caseRecordTypes.length == 0 ? false : true
            this.model = this.ContributorMenu
        })
    }

    getDocumentList() {
        this.docAiservice.GetAllHumanTAsk(this.documentQuery).subscribe(
            (data: any) => {
                // const newDocuments = data.tasks;
                this.documentTotalCount = data.totalCount
                this.DocumentsList = data.tasks
                this.getMaxResultCount(this.documentQuery)
                // console.log(this.DocumentsList, 'new documents')
                // console.log(
                //     this.DocumentsList.length,
                //     this.documentTotalCount,
                //     'total'
                // )
                // console.log(
                //     this.documentTotalCount - this.DocumentsList.length,
                //     'skip'
                // )

                // Update the DocumentsMenu
                const lastMenu = this.DocumentsMenu[0].items.length - 1
                this.DocumentsMenu[0].items = []

                for (const doc of this.DocumentsList) {
                    this.DocumentsMenu[0].items.push({
                        label: doc.name,
                        menuType: 'case-types',
                        class: 'upload-doc doc-list',
                        command: () => this.gotoDocumentDetails(doc),
                    })
                }

                this.documentRecord = this.DocumentsList
                this.DocumentsMenu[0].items[lastMenu].visible =
                    this.documentRecord.length == 0 ? false : true
                this.model = this.DocumentsMenu
            },
            (error) => {
                console.error('Error fetching documents:', error)
            }
        )
    }

    getMaxResultCount(query: string): number {
        const match = query.match(/&MaxResultCount=(\d+)/)
        this.skipCount = query.match(/&SkipCount=(\d+)/)
        // console.log(this.skipCount)
        this.skipCount = parseInt(this.skipCount[1], 10)
        // console.log(this.skipCount, 'after')
        return match ? parseInt(match[1], 10) : 0 // If no match, default to 0
    }

    // Method to update SkipCount within the query string
    updateMaxResultCount(query: string, newMaxResultCount: number): string {
        // Use a regular expression to find and replace &SkipCount=<value>
        return query.replace(
            /(&MaxResultCount=)(\d+)/,
            `$1${newMaxResultCount}`
        )
    }

    
    loadMoreDocuments() {
        // Extract the current SkipCount from the query string
        let currentSkipCount = this.getMaxResultCount(this.documentQuery)

        // Check the current SkipCount and then increment by 10
        if (currentSkipCount !== null && !isNaN(currentSkipCount)) {
            const newMaxResultCount = currentSkipCount + 10 // Add 10 to the current SkipCount

            // Update the query with the new skip count
            this.documentQuery = this.updateMaxResultCount(
                this.documentQuery,
                newMaxResultCount
            )

            // Call your API with the updated query
            // console.log('Updated query:', this.documentQuery)

            // Fetch the next set of documents from the API and append to the existing ones
            this.getDocumentList()
        }
    }

    gotoCaseType(caseType: any) {
        this.docAiservice.filterCaseTypeSubject.next(caseType.id)
        this.docAiservice.CaseTypeName.next(caseType.name)
        this.router.navigate(['/case/caselist/', caseType.id])
    }

    gotoDocumentDetails(doc: any) {
        // this.docAiservice.documentTaskListSubject.next(null);
        this.docAiservice.documentTaskListSubject.next(doc.id);
        this.router.navigate(['/document/processed/', doc.id], {
            state: { documentNumber: 1 },
        })
    }

    SelectRole(role: string) {
        this.model = []
        this.breadcrumbService.setBreadcrumbs([])
        if (role == 'contributor') {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Contributor', route: '' },
            ])
            //   for testing more records
            //   this.getCaseTypes()
            this.model = this.ContributorMenu
            this.selectedMenu = 'contributor'
            this.getCaseTypes()
        } else if (role == 'Documents') {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Documents', route: '' },
            ])
            //   for testing more records
            //   this.getCaseTypes()
            this.model = this.DocumentsMenu
            this.selectedMenu = 'Documents'
            this.getDocumentList()
        } else if (role == 'admin') {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Administration', route: '' },
            ])
            this.selectedMenu = 'admin'
            this.model = this.AdminMenu
        } else {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Developer', route: '' },
            ])
            this.selectedMenu = 'developer'
            this.DeveloperMenu = [
                {
                    icon: 'pi pi-user',
                    label: 'Functions',
                    command: () => this.navigateToFunction(),
                    items: [
                        {
                            visible: this.authService.hasPermission(
                                __Permissions.functionCall
                            ),
                            label: 'Functions',
                            menuType: 'functions',
                            command: () => this.navigateToFunction(),
                            class: 'upload-doc functioNIcon',
                            data: { breadcrumb: 'Functions' },
                            items: [],
                        },
                        {
                            // icon: 'pi pi-cog',
                            visible: this.authService.hasPermission(
                                __Permissions.functionCall
                            ),
                            menuType: 'functions',
                            label: 'Automation',
                            class: 'automationIcon',
                            routerLink: ['/automation'],
                            data: { breadcrumb: 'Teams' },
                        },
                    ],
                },
            ]

            this.service
                .get_withFullUrl(this.service.apiurl + Lighthouse_EndPoint.menu)
                .subscribe((menu: ILightHouseMenuItem[]) => {
                    for (const menuItem of menu) {
                        this.DeveloperMenu[0].items[0].items.push({
                            label: menuItem.name,
                            icon: menuItem.icon,
                            class: 'functioniconclas',
                            menuType: 'functions',
                            command: () => this.findData(menuItem),
                        })
                    }
                    this.allFunctionList = menu
                    this.model = this.DeveloperMenu
                })
        }
        // if (this.authService.selectedUserRole.roleId == 1 && role.roleId != 1) {
        //     // if admin and try to navigate to other roles then do nothing
        //     // act like disable
        // } else {
        // this.authService.selectedUserRole = role;
        // if (this.authService.selectedUserRole.roleId == 3) {
        //     this.router.navigate(['/functions']).then( ()=>
        //         this.authService.loadApp(true)
        //     )
        //     // this.authService.loadApp(true)
        // }
        // else if(this.authService.selectedUserRole.roleId == 2) {

        //     this.router.navigate(['/document/create']).then(
        //         ()=>{
        //             this.authService.loadApp(true)
        //         }
        //     )
        // }
        // else {
        //     this.router.navigate(['/administration/templateLibraries']).then(
        //         ()=> {
        //             this.authService.loadApp(true)
        //         }
        //     )
        // }
    }
    // }

    checkForSideNavPermission() {
        // let contributorCount = 0
        this.contributorPermissions.forEach((element) => {
            if (this.authService.hasPermission(element, false)) {
                this.hasContributorPermission = true
                // console.log(element);
            }
        })
        this.documenttab.forEach((element) => {
            if (this.authService.hasPermission(element, false)) {
                this.hasContributorPermission = true
                // console.log(element);
            }
        })
        this.adminPermissions.forEach((element) => {
            if (this.authService.hasPermission(element, false)) {
                this.hasAdminPermission = true
                // console.log(element);
            }
        })

        this.developerPermissions.forEach((element) => {
            if (this.authService.hasPermission(element)) {
                this.hasDeveloperPermission = true
                // console.log(element);
            }
        })

        if (
            !this.hasDeveloperPermission &&
            !this.hasAdminPermission &&
            !this.hasContributorPermission
        ) {
            this.router.navigate(['/no-access'])
        }
    }
    selectedActivetab() {
        this.ContributorMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('contributor')
            }
        })
        this.DocumentsMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('Documents')
            }
        })
        this.AdminMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('admin')
            }
        })
        this.DeveloperMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('developer')
            }
        })
    }

    navigateToFunction(): void {
        this.router.navigate(['/functions'])
    }
    findData(item: any) {
        const dataToDisplay = this.getDataForItem(item)
        this.allFunctionListDeail = dataToDisplay
        this.service.filterData(this.allFunctionListDeail)
    }
    getDataForItem(item: any) {
        return this.allFunctionList.find(
            (dataItem) => dataItem.name === item.name
        )
    }
}
