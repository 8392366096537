import { Lighthouse_EndPoint } from 'src/app/EndPointUrls/apiEndPoints.enum';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { IFunctionDefinition, IFunctionVersion, ILightHouseMenuItem } from 'src/app/document/interfaces/ILightHouseMenuItem';
import { parse } from 'path';

@Injectable({
  providedIn: 'root'
})
export class LighthouseService extends BaseService {
    filterDataList: EventEmitter<any> = new EventEmitter<any>();
    apiurl=environment.functionsApiUrl;
    get openBotsFunction(){ return ` ${environment.openBotsFunctionApi}`}
    selectedMenuFunctions?:IFunctionDefinition[]=[];
    selectedFunction?={} as IFunctionDefinition;
     constructor(inject:Injector) {
    super(inject);
    }
    changeFile(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
      filterData(data:any){
        this.filterDataList.emit(data)
      }
    getschema(schema:string)
    {
        return this.http.get(`../../assets/json-schema/${schema}.json`);
    }
    getFunctionVersion(definitionId: string) {
        return this.http.get<IFunctionVersion>(`${this.apiurl}FunctionVersion/definition/${definitionId}`)
    }
    getFunctionDefinition(definitionId: string) {
        return this.http.get<IFunctionVersion>(`${this.apiurl}${Lighthouse_EndPoint.definition}/${definitionId}`)
    }
    getfunction_withversion(defId:string){
        var version=this.getFunctionVersion(defId);
        var def=this.getFunctionDefinition(defId);
        return forkJoin([def,version]);
    }
    //  run(content:any, functionVersionId:any) {
    //      var form = new FormData();
    //      let postobj={} as any;
    //      let hasContent:boolean=false;
    //      Object.keys(content).forEach((key)=>{
    //          if(key!=='file')
    //          {
    //              hasContent=true;
    //              postobj[key]=content[key];
    //          }
    //      });
    //      if(content=undefined && content['file']!=undefined)
    //      {
    //          const file:File = content["file"];
    //          const blob = new Blob([file], { type: file.type });
    //          form.append('files', blob, file.name);
    //      }
    //      if(hasContent)
    //      {
    //          postobj = JSON.stringify(postobj).replace(/"/g, "\\\"");
    //          postobj = `"${postobj}"`
    //          form.append('jsonSchemaContent',postobj);
    //      }
    //      return this.http.post(this.apiurl+'Run/' + functionVersionId, form)
    //    }

      runTokenizer(content:any, slug:any) {
        content = JSON.stringify(content).replace(/"/g, '\"');
        //content = `"${content}"`
        let formData = new FormData();
        formData.append("jsonSchemaContent", content)
        return this.http.post(this.apiurl+'Run/' + slug, formData)
      }


      run_old(content:any, slug:any) {
        var form = new FormData();
        var jsonSchemaContent={} as any;
          let parsedData =  JSON.parse(this.selectedFunction.functionVersion.inputJSONSchema);
        Object.keys(content).forEach((key)=>{
            let contenttype=parsedData?.schema?.properties[key]?.media?.contentType;
            if((contenttype==="blob" ) 
            && (key=='file'|| key=='image' || key=='Image' || key=='files'))
            {
                // as a blob 
                let file:File = content[key];
                let blob = new Blob([file], { type: file.type });
                form.append(key,blob,file.name);
            }
            else
            {
                // image as  base64 string 
                    jsonSchemaContent[key]=content[key];
            }
        });
        if(Object.keys(jsonSchemaContent).length)
        {
            const omrStringt =  JSON.stringify(jsonSchemaContent)        
            form.append('jsonSchemaContent',JSON.stringify(omrStringt));
        }
        return this.http.post(this.apiurl+'Run/' + slug, form)
      }
    
}
