import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LogOutComponent, } from './log-out/log-out.component';
import { __Permissions } from './core/permissionEnum/permissions';
import { PermissionGuard } from './core/guards/permission.guard';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { OrganizationBlockedComponent } from './shared/components/organization-blocked/organization-blocked.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { SubscriptionWithErrorComponent } from './shared/components/subscription-with-error/subscription-with-error.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: AppLayoutComponent,
                    canActivate: [
                        MsalGuard
                    ],
                    children: [
                        {
                            path: 'subscription-with-error',
                            component: SubscriptionWithErrorComponent ,
                    },
                    {
                        path: 'no-access',
                        component: NoAccessComponent,
                    },
                    {
                        path: 'organization-blocked',
                        component: OrganizationBlockedComponent,
                    },
                        {
                            path: 'reports', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule ),
                            data:{permission:__Permissions.reports},
                                canActivate:[PermissionGuard]
                        },
                        {
                            path: 'document', loadChildren: () =>  import('./document/document.module').then(  (m) => m.DocumentModule ),
                            data:{permission:__Permissions.documentList},
                            canActivate:[PermissionGuard]
                        },
                        {
                            path:'case',
                            loadChildren : () => import('./case-management/case-management.module').then(m=> m.CaseManagementModule),
                            data: { permission: __Permissions.select_Case },
                            canActivate:[PermissionGuard]
                        },
                        {
                            path: 'administration', loadChildren: () =>import( './administration/administration.module'  ).then((m) => m.AdministrationModule),

                        },
                        {
                            path: 'functions', loadChildren: () => import('./functions/functions.module').then((m) => m.FunctionsModule),
                                data:{permission:__Permissions.function},
                                canActivate:[PermissionGuard]
                        },
                        // automation
                        {
                            path: 'automation', loadChildren: () => import('./automations/automations.module').then((m) => m.AutomationsModule),
                                data:{permission:__Permissions.function},
                                canActivate:[PermissionGuard]
                        },
                        {
                            path: 'entityRelation', loadChildren: () => import( './entity-relation/entity-relation.module'  ).then((m) => m.EntityRelationModule),
                        },
                        {
                            path: 'batch', loadChildren: () => import( './documents-Batch/documents-Batch.module'  ).then((m) => m.DocumentsBatchModule),
                        },
                        // {
                        //     path: '',
                        //     redirectTo: 'document/create',
                        //     pathMatch: 'full',
                        // },
                    ],
                },
                {
                    path:'logout', component:LogOutComponent
                }
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
                // Don't perform initial navigation in iframes or popups
                initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
