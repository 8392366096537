<div *ngIf="cType===undefined" >
    <input class="formly-field inputClass" type="input" [formControl]="formControl" [formlyAttributes]="field">
      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
</div>
<div *ngIf="cType==='textarea'">
    <textarea [formlyAttributes]="field"  [rows]="10" [cols]="100" [formControl]="formControl" pInputTextarea [autoResize]="true"></textarea>
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
</div>
<div *ngIf="cType!==undefined">
    <div  *ngIf="cType==='image/png'"  class="row toke-line">
        <div class="col-md-12">
          <img [src]="image">
        </div>
      </div>
    <ngx-json-viewer *ngIf="cType==='application/json'" [json]="data"></ngx-json-viewer>
    <input class="formly-field"  *ngIf="cType==='file'"  class="playgroundInput" (change)="fileUpload($event)" type="file" [formlyAttributes]="field" />
</div>



