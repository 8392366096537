import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-text-input',
  templateUrl: './formly-text-input.component.html',
  styleUrls: ['./formly-text-input.component.scss']
})
export class FormlyTextInputComponent extends FieldType<FieldTypeConfig> implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit() {
    
    // console.log(this.field.formControl.value);
    
    if (this.field.formControl.value == '') {
      this.field.props['review'] = false;
    }
    if (this.field.props['confidence'] == 0) {
      this.field.props['review'] = false;
    }
  }
  // onBlurEvent() {
  //   console.log(this.model)
  // }
  setConfidence() {
    this.field.props['review'] = false
    this.field.props['confidence'] = 1
  }
  clearValue() {
    this.field.formControl.setValue(''); // Clear the value
    this.field.props['confidence'] = 1
  }
  reviewField() {

    this.field.props['review'] = false
    this.field.props['confidence'] = 1
  }
}
