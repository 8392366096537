<div class="noRightPadding">
  <div [class.upload-files-error]="docUploadError" class="upload-files" [ngClass]="{'disabled' : !showUploader}">
    <div class="drop-container" *ngIf="showUploader"
      ngFileDrop
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
      <h2 class="dropHead" *ngIf="!showConfigFlag">
        <img src="../../../assets/layout/images/drag_icon.svg" alt="Drag Icon" />
         Drag Your Document Here...
      </h2>
      <h2 class="dropHead" *ngIf="showConfigFlag">
        <img src="../../../assets/layout/images/drag_icon.svg" alt="Drag Icon" />
         Import Your Document Here...
      </h2>
      <p class="orHead"><img src="../../../assets/layout/images/line_icon.svg"><span>OR</span><img src="../../../assets/layout/images/line_icon.svg"></p>
      <label class="upload-button" *ngIf="showUploader">
        <input id="file_upload" type="file" #myFileInput class="btn-files custom-file-input" ngFileSelect [options]="options"
          (uploadOutput)="onUploadOutput($event)" *ngIf="!ariaDisabled" [uploadInput]="uploadInput" multiple><span>Browse File</span>
      </label>
      <!-- <p class="uploadDes">Please do not share any confidential documents that may contain data deemed sensitive, PII or PHI Data.​
        These documents may be reviewed by our AI trainers to improve our systems.</p> -->
    </div>
    <div class="drop-container" *ngIf="!showUploader">
      <p>Max file reached</p>
    </div>
    <div class="drop-container" *ngIf="!showUploader">
      <p>Max file reached</p>
    </div>
  </div>

  <div style="height: 20px !important;" class="progress form-group" *ngIf="progress > 0">
    <div [class.progress-bar-danger]="docUploadError" [class.progress-bar-info]="!docUploadError"
      class="progress-bar progress-bar-striped" role="progressbar" [style.width.%]="progress">
      {{progress}}%
    </div>
  </div>
  <span style="color: red;" *ngIf="docUploadError">Document Upload Failed</span>

  <div class="filelist" *ngIf="files.length > 0 && API===undefined">
    <ul style="padding: 10px 20px">
      <li style="font-size: 14px;" *ngFor="let file of files" >
        <i (click)="DeleteFileFromList(file)" id="deletefile" class="pi pi-trash"></i>
        <span class="fileNameDel">{{file?.name}}  </span>
        <span class="fileNameSize">{{file?.size | fileSize}}</span>
      </li>
    </ul>
  </div>
</div>
