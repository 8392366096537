import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from 'src/app/layout/breadcrumb/breadcrumb.component';
@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  setBreadcrumbs(breadcrumbs: (Breadcrumb | string)[]) {

    const breadcrumbObjects: Breadcrumb[] = breadcrumbs.map(breadcrumb => {
      if (typeof breadcrumb === 'string') {
        return { label: breadcrumb, route: '' };
      } else {
        return breadcrumb as Breadcrumb;
      }
    });
        this.breadcrumbsSubject.next(breadcrumbObjects);


  }
constructor() { }
}
