<!-- view dialog -->
<div class="permissionTreeDialog">
    <h3>Permissions</h3>
    <div class="closeicon">
        <i (click)="closePopup()" class="pi pi-times"
            style="font-size: 1.5rem; position: absolute; top: 22px; right: 22px; cursor: pointer;"></i>
    </div>
    <div class="permissionTreeDialog-body">
        <div class="row">
            <div class="col-12">
                <div class='form-group'>
                    <input type='text' (appTrim)="$event" (input)="filterPermissions($event)" [(ngModel)]="filter" class='form-control' placeholder='Search'>
                </div>
                <!-- [filter]="true"  -->
                <p-tree [value]="treeData" [filter]="false" selectionMode="checkbox"
                 class="w-full" 
                    [(selection)]="selectedPermissions" (onNodeSelect)="nodeSelect($event)"
                    (onNodeUnselect)="onNodeUnselect($event)" ></p-tree>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12 fieldButtonPopup text-right">
                <button id="cancel-addForm" type="button" class="btn btn-normal" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button  id="create-addForm" type="submit" pButton
                    class="btn btn-primary blue" (click)="selectPermissions()">
                    <ng-container>
                        <i class="fa fa-save"></i>
                        &nbsp;
                        <span>  {{data ? 'Save' : 'Select'}}</span>
                    </ng-container>
    
                </button>
            </div>
        </div>
    </div>
</div>