import { AfterViewInit, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
@Component({
    selector: 'formly-date-picker',
    templateUrl: './formlyDatePicker.component.html',
    styleUrls: ['./formlyDatePicker.component.scss'],
})
export class FormlyDatePickerComponent extends FieldType implements AfterViewInit {
    constructor() {
        super();
    }

    ctrl(){
        return this.formControl as FormControl;
    }

    ngAfterViewInit(): void {
        // this.formControl.valueChanges.subscribe(
        //     res => {
        //         if (res == null) {
        //             this.date = null;
        //         }
        //     }
        // )


        // if (this.formControl.value != null && this.formControl.value != undefined && this.IsDate(this.formControl.value)) {
        //     this.date = new Date(this.formControl.value);
        // }
        // this.cdk.detectChanges();
    }

}
