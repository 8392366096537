<p-dialog #deletepopup id="deletepopupid" class="delConfirm" [(visible)]="deleteDialogVisible" [modal]="true" [responsive]="true" [baseZIndex]="10000" (onHide)="onDialogHide()">
  <div *ngIf="confirmationHeader == 'Delete'" class="deleteImg">
    <i class="pi pi-trash"></i>
  </div>
  <p class="text-center">{{confirmationMessage}}</p>
  <p-footer>
    <button [class.deleteBtnPopup]="confirmationHeader == 'Delete'" [class.confirmBtnPopup]="confirmationHeader != 'Delete'" type="button" pButton (click)="confirmDelete()">{{confirmationHeader}}</button>
    <button type="button" pButton (click)="hideDeleteDialog()" class="p-button-secondary cancelBtnPopup">Cancel</button>
  </p-footer>
</p-dialog>
