import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-see-more-less',
  templateUrl: './see-more-less.component.html',
  styleUrls: ['./see-more-less.component.scss']
})
export class SeeMoreLessComponent implements OnInit {

  @Input() text: string = '';
  @Input() size: number ;
  isExpanded: boolean = false;
  shouldShowButton: boolean = false;
  
  constructor() { }

  ngOnInit() {
    this.shouldShowButton = this.text.length > this.size;
  }
  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  }
}
