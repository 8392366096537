import { Component, EventEmitter, Input, OnInit, Output, Pipe, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { IOBActionColumn, IOBMenuItem, IOBTableColumn, IOBTableOutput, IOBTableSettings } from 'ob-ng-library';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Itestingtable } from './Itestingtable';
// import { IOBActionColumn, IOBMenuItem, IOBTableColumn, IOBTableOutput, IOBTableSettings } from '../../interfaces/interfaces';

@Component({
    selector: 'ob-ng-table-testing',
    templateUrl: './ob-table-Testing.component.html',
    styleUrls: ['./ob-table-Testing.component.scss']
})
export class TestingTableComponent implements OnInit, AfterViewInit, AfterViewChecked {
    uuid = crypto.randomUUID();
    @Input() settings: Itestingtable;
    // @Input() paginator
    @Output() tableOutput: EventEmitter<IOBTableOutput> = new EventEmitter();
    @Output() onRowReorder: EventEmitter<any> = new EventEmitter();
    @ViewChild('dt') public table?: Table;
    @ViewChild("paginator") paginator?: Paginator;
    @ViewChildren('menuButton_td') menuButtons_tds: QueryList<ElementRef<HTMLDivElement>>;

    protected selectAllTooltip: string = 'Select All';
    protected selectAll: boolean = false;
    selectedRows: any[] = [];
    selected_Row_by_radio: any = false;
    checkpermissions() {
        if (this.settings?.menuButton != undefined) {

            let table = document.getElementById(this.uuid)
            if (table) {
                let menuButtons_tds = table.getElementsByClassName("menuButton_td");
                for (let index = 0; index < menuButtons_tds.length; index++) {
                    let menuBtn = menuButtons_tds[index].firstChild as any;
                    let ul = menuButtons_tds[index].lastChild as any;
                    let lis = ul.children as HTMLCollection;
                    let counter = 0;
                    for (let i = 0; i < lis.length; i++) {
                        if (window.getComputedStyle(lis[i])?.display != undefined && window.getComputedStyle(lis[i]).display == 'none') {
                            counter = counter + 1;
                        }
                    }
                    if (counter == lis.length) {
                        menuBtn.disabled = true; //
                        this.cdf.detectChanges();
                    }
                }
            }
        }
    }

    constructor(public router: Router, public cdf: ChangeDetectorRef) { }
    ngAfterViewChecked(): void {
        this.checkpermissions();
    }
    ngAfterViewInit(): void {
        this.lighthouseAccesibilityChecks();
    }

    ngOnInit(): void {
        if (this.settings.selectable && this.settings.selectableType === "radio") {
            this.selectedRows[0] = {} as any;
        }
    }

    fetchTableData(event: LazyLoadEvent) {
        event = this.applyDefaultFilters(event);
        if (this.settings.selectable) {
            this.selectAll = false;
            this.selected_Row_by_radio = false;
        }
        this.tableOutput.emit({ event: event, queryString: this.makeODataQuery(event, this.settings.columns) });
    }

    applyDefaultFilters(event: LazyLoadEvent) {
        this.settings.columns.forEach(col => {
            if (col.defaultValue) {
                event.filters[col.field] = {
                    value: col.defaultValue,
                    matchMode: "equals"
                }
            }
        });

        return event;
    }

    view(id: any) {
        this.router.navigate(['pages/control/controlview/' + id]);
    }

    actionClick(action: IOBActionColumn, record: any) {
        action.function(record);
    }

    menuClick(menu: IOBMenuItem, record: any) {
        menu.function(record);
    }

    linkClick(col: IOBTableColumn, record: any) {
        col.function(record);
    }
    applyFilter(value: any, field: any) {
        if (value == "" || value == null || value == undefined) {
            (this.table.filters[field] as FilterMetadata).value = '';
            //   this.paginator.changePage(this.paginator.getPage());
            this.table._filter();
        }
        else {
            this.table.filter(value, field, 'equals');
        }
    }
    onRowReorderChanged(rowData: any) {

        this.settings.data;
        let result = {
            dragIndex: rowData.dragIndex,
            dropIndex: rowData.dropIndex,
            orderChanged: rowData.dropIndex - rowData.dragIndex,
            data: this.settings.data[rowData.dropIndex],

        }

        this.onRowReorder.emit(result)
    }


    //#region helper functions

    lighthouseAccesibilityChecks() {
        let elements = document.getElementsByClassName("p-paginator-element");
        for (let i = 0; i < elements.length; i++) {
            elements[i].setAttribute("aria-label", i.toString());
            elements[i].setAttribute("name", i.toString());

        }

        let dropDownTrigger = document.getElementsByClassName("p-dropdown-trigger");
        for (let i = 0; i < dropDownTrigger.length; i++) {
            dropDownTrigger[i].setAttribute("aria-label", i.toString());
            dropDownTrigger[i].setAttribute("name", i.toString());
        }

        let dropDownList1 = document.getElementsByTagName("input");
        for (let i = 0; i < dropDownList1.length; i++) {
            dropDownList1[i].setAttribute("aria-label", i.toString());
            dropDownList1[i].setAttribute("name", i.toString());
        }
    }

    OnPageinatorChange($event: any) {
        this.table.onPageChange($event);
    }

    hidemenu(id: any) {
        // let menuid = "menu-ul-" + id;
        setTimeout(() => {
            document.getElementById(id).style.display = "none";

        }, 200);
    }

    onclearDate(field: string) {
        (this.table.filters[field] as FilterMetadata).value = null;
        this.table._filter();
    }


    showMenu(id: any) {
        // let menuid = "menu-ul-" + id;
        var counter: number = 0;
        let optionsAvailable: Boolean = true;
        if (document.getElementById(id).children != undefined) {
            let collections = document.getElementById(id).children;
            for (let index = 0; index < collections.length; index++) {
                if (window.getComputedStyle(collections[index])?.display != undefined && window.getComputedStyle(collections[index]).display == 'none') {
                    counter = counter + 1;
                }
            }
            if (counter == (document.getElementById(id).children as any).length) {
                document.getElementById(id).style.display = "none";
                optionsAvailable = false;
            }
        }
        if (optionsAvailable == true) {
            if (document.getElementById(id).style.display == "none") {
                document.getElementById(id).style.display = "block";
            }
            else {
                document.getElementById(id).style.display = "none";
            }
        }
    }
    public makeODataQuery(event: LazyLoadEvent, cols: any[]) {
        let ODataFilter = '';
        Object.keys(event.filters).forEach((key) => {
            if (event.filters[key].value != null && event.filters[key].value != '') {
                // second  filter
                if (ODataFilter != '') {
                    ODataFilter = ODataFilter + ' and ';
                    ODataFilter = this.getInnerQuery(event, key, ODataFilter, cols);
                } else {
                    ODataFilter = '?$filter=';
                    ODataFilter = this.getInnerQuery(event, key, ODataFilter, cols);
                }
            }
        });
        if (ODataFilter.includes('?')) {
            ODataFilter = ODataFilter + '&';
        }
        else {
            ODataFilter = ODataFilter + '?';
        }
        ODataFilter = ODataFilter + '$top=' + event.rows + '&';
        ODataFilter = ODataFilter + '$skip=' + event.first;
        if (event.sortField) {
            let sorting = +event.sortOrder == -1 ? 'desc' : 'asc';
            ODataFilter = ODataFilter + '&$orderby=' + event.sortField + '+' + sorting;
        }
        return ODataFilter;
    }

    public getInnerQuery(event: any, key: any, ODataFilter: any, cols: any[]) {
        if (event.filters[key].value == "Yes" || event.filters[key].value == "No") {
            if (event.filters[key].value == "Yes") {
                ODataFilter = ODataFilter + `${key} eq true`

            }
            if (event.filters[key].value == "No") {
                ODataFilter = ODataFilter + `${key} eq false`
            }
        }
        else {
            let col = cols.find(x => String(x.field).toLowerCase() == key.toLowerCase());
            if (col?.type == 'dropdown') {
                ODataFilter = ODataFilter + `${key} eq '${event.filters[key].value}'`
            }
            else if (col?.type == 'number') {
                ODataFilter = ODataFilter + `${key} eq ${event.filters[key].value}`
            }
            else if (col?.type == 'text') {
                ODataFilter = ODataFilter + `${key} eq '${String(event.filters[key].value).toLowerCase()}'`
            }
            else if (col?.type == 'date') {
                ODataFilter = ODataFilter + `${key} eq datetime'${this.get_ISO_Date_WithOutTimeSpan(event.filters[key].value)}'`
            }
            else {
                if (this.isNumber(event.filters[key].value)) {
                    ODataFilter = ODataFilter + `${key} eq ${event.filters[key].value}`
                }
                else {

                    ODataFilter = ODataFilter + `${key} eq '${String(event.filters[key].value).toLowerCase()}'`
                }
            }
        }


        return ODataFilter;
    }

    public get_ISO_Date_WithOutTimeSpan(date: Date) {
        let hours = date.getHours().toString();
        let minuts = date.getMinutes().toString();
        let seconds = date.getSeconds().toString();
        let month = (date.getMonth() + 1).toString();
        let theDate = date.getDate().toString();
        if (date.getHours() < 10) {
            hours = '';
            hours = "0" + date.getHours();
        }
        if (date.getMinutes() < 10) {
            minuts = '';
            minuts = "0" + date.getMinutes();
        }
        if (date.getSeconds() < 10) {
            seconds = '';
            seconds = "0" + date.getSeconds();
        }
        if ((date.getMonth() + 1) < 10) {
            month = '';
            month = "0" + (date.getMonth() + 1);
        }
        if (date.getDate() < 10) {
            theDate = '';
            theDate = "0" + date.getDate();
        }
        return date.getFullYear() + '-' + month + '-' + theDate;
    }
    public isNumber(value: any) {
        if (!isNaN(Number(value))) {
            return true
        } else {
            return false;
        }
    }
    onCheckboxSelect(checked: boolean, rowData: any) {
        if (checked) {
            this.selectedRows.push(rowData);
        }
        else if (checked == false) {
            this.selectedRows = this.selectedRows.filter(row => row.id != rowData.id);
        }
    }
    onradioSelect(record: any) {
        this.selectedRows = [];
        this.selectedRows.push(record);
    }
    onselectAll(checked: boolean) {
        this.selectedRows = [];
        if (checked) {
            this.selectAllTooltip = 'Unselect All';
            this.settings.data.forEach((record: any) => {
                if (record.selected != undefined) {
                    record.selected = true;
                    this.selectedRows.push(record);
                }
            });
        }
        else if (checked == false) {
            this.selectAllTooltip = 'Select All';
            this.settings.data.forEach((record: any) => {
                if (record.selected != undefined) {
                    record.selected = false;
                    this.selectedRows = [];
                }
            });
        }
    }
    invertSelect() {
        this.selectedRows = [];
        this.settings.data.forEach((record: any) => {
            if (record.selected != undefined) {
                record.selected = !record.selected;
                if (record.selected == true) {
                    this.selectedRows.push(record);
                }
            }
        });
    }
    //#endregion
}
