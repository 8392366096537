import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-button',
  template: ` '<button>{{to.label}}</button>'`,
})
export class FormlyButtonComponent extends FieldType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
  // onClick($event) {
  //   if (this.to.onClick) {
  //     this.to.onClick($event);
  //   }

  // }
}
