import { Pipe, PipeTransform } from '@angular/core';
import { CASTSTATUS, STATUS } from '../enums/CastStatus.enum';

@Pipe({
  name: 'castStatus'
})
export class CastStatusPipe implements PipeTransform {
  transform(value:string): any {
    if(value)
    {
        if(value==STATUS.AwaitingVerification)
        {
            return CASTSTATUS['Awaiting Verification']
        }
        else if(value==STATUS.Created)
        {
            return CASTSTATUS['Created'];
        }
        else if(value==STATUS.InProgress)
        {
            return CASTSTATUS['In Progress'];
        }
        else if(value=="InProgress"){
            return CASTSTATUS['In Progress'];
        }
        else
        {
            return value;
        }
    }
    else
    {
      return null;
    }
  }
}
