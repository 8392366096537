import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  constructor(private breadcrumbService: BreadCrumbService,  private router: Router,private location: Location) {}
  
  ngOnInit() {
    this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
      console.log(this.breadcrumbs,'breadCrumb')
    });
  }

 

  goBack() {
    this.location.back();
  }


  navigateToRoute(route: string) {
    this.router.navigateByUrl(route);
  }
}
// export interface Breadcrumb {
//   label: string;
//   route: string;
// }
export interface Breadcrumb {
  label: string;
  route: string;
   back?: boolean;
  subChild?: {
    label: string;
    route: string;
     back?: boolean;
  };
  anotherSubChild?: {
    label: string;
    route: string;
     back?: boolean;
};
}