<div class="mb-3 backInputColor">
    <div class="row inputLeftRightPad">
      <div class="col-md-8 col-sm-12 noLeftPadding">
        <h3 class="tileLabelTxt" *ngIf="props.label">{{ props.label }}</h3>
      </div>
      <div class="col-md-4 col-sm-12 noRightPadding">
        <div *ngIf="props['hasMultipleRecord'] == true  || props['hasMultipleRecord'] == 'true'" class="d-flex flex-row-reverse">
          <button [disabled]="formControl.disabled" class="btn btn-primary createBtn btnRightMargin btnInputPad" type="button" (click)="add()">Add</button>
        </div>
      </div>
    </div>
    <p *ngIf="props.description">{{ props.description }}</p>
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
    <div *ngFor="let item of field.fieldGroup; let i = index" class="row align-items-start rowBlock">
      <formly-field class="col" [field]="item"></formly-field>
      <div class="inputLeftRightPad"  *ngIf="this.field.fieldGroup.length > 1">
        <button [disabled]="formControl.disabled" class="btn btn-danger removebtnProcessed btnInputPad" type="button" (click)="remove(i)">Remove</button>
      </div>
    </div>
</div>