import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(fileSizeInBytes: number): string {
    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + ' bytes';
    } else if (fileSizeInBytes < 1024 * 1024) {
      const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
      return fileSizeInKB + ' KB';
    } else {
      const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
      return fileSizeInMB + ' MB';
    }
  }
}
