import { AppConsts } from 'src/app/core/constraints/appConsts';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentAIService } from 'src/app/shared/services/documentAI.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/shared/components/baseComponent';
import { Pagenames } from 'src/app/EndPointUrls/pagenames.enum';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  templateLibraries: any[] = [];
  urlPresent = AppConsts.homeVideoUrl
  videoUrl:any=undefined;
  constructor(private inject: Injector ,private sanitizer:DomSanitizer,private router: Router, private service: DocumentAIService) {
    super(inject ,Pagenames.Home);
   }

  ngOnInit() {
    this.videoUrl=this.sanitizer.bypassSecurityTrustResourceUrl(AppConsts.homeVideoUrl);
    // this.videoUrl = ''
    // this.getTemplateLibraries();
  }
  vemioUrl() {
    return
  }
  navigateToIndustry(template?:any) {
    // item.templateLibrary.name

    if (template) {
      this.service.selectedTemplate = template
      localStorage.setItem("templateId", template.id)
      // this.router.navigate(['/document/'+template.name.replace(/\s/g, "")])
      // this.router.navigate(['/document/create'])
    }
  }
  // getTemplateLibraries() {
  //   this.service.GetTemplateLibraries().subscribe(
  //     (data:any) => {
  //       this.templateLibraries = data.result.items
  //       if (this.templateLibraries.length < 1) {
  //         this.service.GetJSon('templateLibraries.json').subscribe(
  //           (res:any) =>{
  //             this.templateLibraries = res
  //           }
  //         )
  //       }
  //     }
  //   )
  // }
}
