<nav>
  <ng-container *ngFor="let crumb of breadcrumbs; let last = last">

    <!-- For non-last crumbs -->
    <ng-container *ngIf="!last">
      <!-- Check for the 'back' flag before setting up navigation -->
      <img src="../../../assets/layout/images/home.svg" alt="icon" style="cursor: pointer;"
        (click)="crumb.back ? goBack() : navigateToRoute(crumb.route)" class="breadcrumb-icon">

      <a class="link" (click)="crumb.back ? goBack() : navigateToRoute(crumb.route)">
        {{ crumb.label }}
      </a>
      <span class="breadArrow">&gt; </span>
    </ng-container>

    <!-- For SubChild -->
    <ng-container *ngIf="crumb.subChild">
      <a class="link" (click)="crumb.subChild.back ? goBack() : navigateToRoute(crumb.subChild.route)">
        {{ crumb.subChild.label }}
      </a>
      <span class="breadArrow">&gt; </span>
    </ng-container>

    <!-- For AnotherSubChild -->
    <ng-container *ngIf="crumb.anotherSubChild">
      <a class="link" (click)="crumb.anotherSubChild.back ? goBack() : navigateToRoute(crumb.anotherSubChild.route)">
        {{ crumb.anotherSubChild.label }}
      </a>
      <span class="breadArrow">&gt; </span>
    </ng-container>

    <!-- Tooltip for the crumb label -->
    <ob-ng-tooltip [data]="crumb.label" [size]="120"></ob-ng-tooltip>
  </ng-container>
</nav>