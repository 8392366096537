
export enum AppEndPoint {
    Home='/document/create',
    function='/functions',
    category='/category',
    definition='/definition',
    Documents = '/document/list',
    BlockOrganization = 'organization-blocked',
    subscriptionError ='/subscription-with-error'
}
