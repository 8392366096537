export enum Pagenames {
    Industry = 'industry',
    Home = 'home',
    Process = 'process',
    waiting = 'waitingUnderProcess',
    ProcessingFaild = 'processingFailed',
    DocumentProcessing = 'documentprocessing',
    EntityRelation = 'entityRelation',
    SignatureExplorer = 'signatureExplorer',
    Templatechecklist = 'templatechecklist',
    User = 'user',
    createCase = 'createCase'
}
