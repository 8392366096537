import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-image-output',
  templateUrl: './formly-image-output.component.html',
  styleUrls: ['./formly-image-output.component.scss']
})
export class FormlyImageOutputComponent  extends FieldType<FieldTypeConfig> implements OnInit {

  result:any;
  constructor( private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if(this.formControl.value!=undefined){
        let objectURL = 'data:image/png;base64,' + this.formControl.value;
        this.result = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }
}
