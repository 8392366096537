import { Component, OnInit, Input, Output, Injector, Inject, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgOption } from '@ng-select/ng-select';
import { BaseComponent } from 'src/app/shared/components/baseComponent';

@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent extends BaseComponent implements OnInit {
  constructor(injector: Injector,
    public dialogRef: MatDialogRef<ViewTemplateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector, '');
  }

  ngOnInit() {    
  }
  closePublishModal() {
    this.dialogRef.close();
  }
}
