import { Component, OnInit, OnChanges } from '@angular/core';
import { FieldArrayType, FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  styleUrls: ['./arraytype.component.scss'],
  templateUrl: './arraytype.component.html'
})
export class ArrayTypeComponent extends FieldArrayType implements OnInit {
  constructor(){
    super();
  }
  ngOnInit(): void {
    // console.log(this.field.fieldGroup.length);
    
  }
}