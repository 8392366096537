<div class="layout-topbar mainHeader innerHeader" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 col-sm-10 col-xs-12 noLeftPadding">
                <button #menubutton aria-label="menuButton" class="p-link layout-menu-button layout-topbar-button"  (click)="layoutService.onMenuToggle(); AddClass()">
                <i class="pi pi-bars" [class.tabDisplayClass]="tabDisplayClass"></i>
                </button>
                <a id="topbar-doc" class="layout-topbar-logo" (click)="gotoHome()">
                    <img src="../../assets/layout/images/document_with_GPT.svg" alt="logo">
                </a>
                <!-- <label>Administrator</label>
                <button id="Administrator"></button>
                <label>Contributor</label>
                <button></button>
                <label>Developer</label>
                <button></button> -->
               <h2 class="topHeadOrg">{{selectedOrganization.name}}</h2>
               <!--  -->

            </div>
            <!-- <div class="col-md-4 col-sm-4 col-xs-12">
                
            </div> -->
            <div class="col-md-2 col-sm-2 col-xs-12 text-right noRightPadding">
                <button (click)="gotoSupport()" class="btn contactBtn">
                    Support
                </button>
                <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
                    <i class="pi pi-ellipsis-v"></i>
                </button>
                <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
                    <button aria-label="profile" (click)="showRightPanel=!showRightPanel" class="p-link layout-topbar-button">
                        <i class="pi pi-user"></i>
                        <span >Profile</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar position="right" [(visible)]="showRightPanel" [baseZIndex]="10000">
    <h3 style="font-weight:normal"></h3>
    <div style="text-align: center;">
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12 avatarImg">
                <img src="../../assets/layout/images/avatar.png" alt="avatar"/>
            </div>
        </div>
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <p-dropdown (onChange)="OnChangeOrganization($event)" [options]="this.authService.loggedInUser.myOrganizations" [(ngModel)]="this.authService.loggedInUser.activeTenantKey" optionLabel="name" optionValue="tenantKey"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12 logoutbtn text-center">
                <button  (click)="manegeSubscriptions()" pRipple type="button" class="p-button-rounded createBtn">Manage Subscriptions </button>
            </div>
        </div>
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                {{this.authService.loggedInUser.email}}
                </div>
        </div>
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <label for="billing"> Billing Status :</label>  {{this.authService.loggedInUser.bill?.status}}
            </div>
        </div>
        <!-- <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                {{this.authService.loggedInUser.bill?.status}}
            </div>
        </div> -->
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                <label for="nextBillingDate">Next Billing Date :</label>
                <div style="display: block;">   {{this.authService.loggedInUser.bill?.nextBillingDate | date:'medium'}}</div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12">
                {{this.authService.loggedInUser.bill?.subscriptionEndDate | date:'medium'}}
            </div>
        </div> -->
        <div class="row">
            <div class="sideBar col-12 col-sm-12 col-xxl-12 logoutbtn text-center">
                <button (click)="logout()" pRipple type="button" class="p-button-rounded createBtn">Logout</button>
            </div>
        </div>

    </div>
</p-sidebar>
