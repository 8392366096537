import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Directive({
  selector: '[authorized]'
})
export class AuthorizedDirective implements OnInit {
    @Input('permission') permission: string;
    @Input('checkBillingStatus') checkBillingStatus: boolean=true;
    currendisplay:any;
  constructor(private elementRef: ElementRef, private authService:AuthService) { }
    ngOnInit(): void {
        this.currendisplay=this.elementRef.nativeElement.style.display;
        this.elementRef.nativeElement.style.display='none';
        this.checkPermission();
    }
    checkPermission(){
        if(!this.authService.hasPermission(this.permission,this.checkBillingStatus))
        {
            this.elementRef.nativeElement.style.display = "none";
        }
        else{
            this.elementRef.nativeElement.style.display=this.currendisplay;
        }
    }
}
