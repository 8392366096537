import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-text-output',
  templateUrl: './formly-text-output.component.html',
  styleUrls: ['./formly-text-output.component.scss']
})
export class FormlyTextOutputComponent  extends FieldType<FieldTypeConfig>  implements OnInit {

  constructor() {
    super();
   }

  ngOnInit() {
  }

}
