<div class="error" *ngIf="control.invalid && (control.dirty || control.touched)">
  <div *ngIf="control.errors['required']">
    {{ fieldName }} is required.
  </div>
  <div *ngIf="control.errors['required']">
    {{ secondfieldName }}
  </div>
  <div *ngIf="control.errors['pattern']">
    {{ fieldName }} is invalid. <br> {{secondfieldName}}
  </div>
  <div *ngIf="control.errors['minlength']">
    {{ fieldName }} should be at least {{ control.errors['minlength'].requiredLength }} characters long.
  </div>
  <div *ngIf="control.errors['maxlength']">
    {{ fieldName }} should not exceed {{ control.errors['maxlength'].requiredLength }} characters.
  </div>
  <div *ngIf="control.errors['min']">
    {{ fieldName }} should be greater than or equal to {{ control.errors['min'].min }}.
  </div>
  <div *ngIf="control.errors['max']">
    {{ fieldName }} should be less than or equal to {{ control.errors['max'].max }}
  </div>
  <div *ngIf="control.errors['invalidEmails']">
    Invalid emails: {{ control.errors['invalidEmails'].join(', ') }}
  </div>

</div>