<div class="inputUpload inputTopPadding inputPaddingCheck">

  <!-- pTooltip="Confidence: {{field.props['confidence']}}" -->
  <!-- <i (click)="reviewField()"  class="pi pi-info-circle" ></i>  -->
  <label class="tooltipLabel" pTooltip="Confidence: {{field.props['confidence']}}"
    tooltipPosition="top">{{field.props.label}} <span *ngIf="field.props.required === true">*</span> </label>
  <div class="positionRelDiv"
    [ngClass]="{confidenceDivMain: !((field.formControl.value == '' || field.props['confidence'] == 0) || field.props['confidence'] >= 0.8)}">
    <!-- <span>{{!((field.formControl.value == '' && field.props['confidence'] == 0) || field.props['confidence'] >= 0.8) }}</span> -->
    <span pTooltip="Confidence: {{field.props['confidence']}}"
      *ngIf="!((field.formControl.value == '' && field.props['confidence'] == 0) || field.props['confidence'] >= 0.8)"
      (click)="reviewField()">{{field.props['confidence'] | number: '1.0-2'}}</span>
    <input *ngIf="field.type === 'string'" class="formly-field inputClass" (change)="setConfidence()"
      [ngClass]="{'input-alert': field.props['review'] === true}" type="input" [formControl]="formControl"
      [formlyAttributes]="field">
    <input *ngIf="field.type !== 'string'" type="number" [formControl]="formControl" [formlyAttributes]="field">
    <input *ngIf="field.type === 'password'" type="password" [formControl]="formControl" [formlyAttributes]="field">

    <div *ngIf="field.props.label && field.type !== 'checkbox'" class="input-group-append btnCrossAbs">
      <button [disabled]="field.props.disabled" [className]="field.className" class="btn btn-outline-secondary clearBtn"
        type="button" (click)="clearValue()"><i class="pi pi-times"></i></button>
    </div>
  </div>

</div>

<div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
  <formly-validation-message [field]="field"></formly-validation-message>
</div>