import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges,
    OnDestroy,
} from "@angular/core";

import { Subscription } from "rxjs";
import { SelectionRectangle, TextSelectEvent } from "src/app/interfaces/selectionRectangle";
import { DocumentAIService } from "src/app/shared/services/documentAI.service";
@Component({
    selector: 'tX-textAreaCanvas',
    templateUrl: './textAreaCanvas.component.html',
    styleUrls: ["./textAreaCanvas.component.css"],
})
export class TextAreaCanvasComponent
    implements OnInit, OnChanges, OnDestroy {
    @Input() ReadOnlyMode?: boolean;
    @Input() SizeChange="";
    fontSize: number = 10;

    text?: string;
    subscriptions: Subscription[] = [];
    hostRectangle: SelectionRectangle | null;
    selectedText: string;

    constructor(
        public service: DocumentAIService,
    ) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.service.UnStructuredText.subscribe((data: any) => {
                this.text = data;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {

        if(this.SizeChange=="inc")
        {
            this.increaseSize();
        }
        else {
            this.decreaseSize();
        }
    }
  
    renderRectangles(event: any): void {
        if (this.ReadOnlyMode == false) {
            (this.selectedText = event.text),
                (this.hostRectangle = event.viewportRectangle);
            if (this.hostRectangle.top >= 1120) {
                this.hostRectangle.top = 1120;

            }
        }
        if (this.ReadOnlyMode == false) {
            (this.selectedText = event.text),
                (this.hostRectangle = event.viewportRectangle);
            if (this.hostRectangle.left >= 25) {
                this.hostRectangle.left = 200;

            }
        }
    }
  
    increaseSize() {
        if (this.fontSize >= 30) {
            return this.fontSize;
        } else {
            this.fontSize = this.fontSize + 10;
        }
    }

    decreaseSize() {
        if (this.fontSize <= 10) {
            return this.fontSize;
        } else {
            this.fontSize = this.fontSize - 10;
        }
    }

    getKey(key: any) {
        return Number(key) + 1;
    }
}


export class Coordinates {
    public startIndex: string;
    public lastIndex: string;
    public label: string;
    public text: string;
    public bgColor?: string;
    public borderColor?: string;
    public turnID?: number;
    public parent: string;
    public index: string | number;
}
