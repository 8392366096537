import { HumanTask } from './../../document/interfaces/HumanTask'
import { FORM_FIELDS } from './../../EndPointUrls/apiEndPoints.enum'
import { EventEmitter, Injectable, Injector } from '@angular/core'
import {
    BehaviorSubject,
    Observable,
    Subject,
    forkJoin,
    map,
    switchMap,
} from 'rxjs'
import { environment } from 'src/environments/environment'
import { IChat } from '../../document/interfaces/IChat'
import { ITemplateLibrary } from '../../document/interfaces/ITemplateLibrary'
import { BaseService } from './base.service'
import { HttpHeaders, HttpParams } from '@angular/common/http'
import { IUserFilter } from '../../interfaces/IUserFilter'
import {
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
} from 'date-fns'
import * as END_POINTS from 'src/app/EndPointUrls/apiEndPoints.enum'
import { IFieldValidation } from 'src/app/interfaces/field-validation'
import { ICaseRecordFields } from 'src/app/document/interfaces/caseRecordFields'
import { FieldBox, IFormFields } from 'src/app/document/interfaces/formFields'
import * as FileSaver from 'file-saver'
import { IMultiSelect } from 'src/app/document/interfaces/IMultiDelete'
import { FormVersion, Template } from 'src/app/interfaces/template'
import { ICheckExpressionFieldLookUp } from 'json-logic-editor'
import {
    IForm,
    ISchema,
} from 'src/app/administration/template-checklist/components/create-or-edit-item/create-or-edit-item.component'
import { ICaseRecordFieldsDto } from 'src/app/interfaces/CaseRecordFieldsDto'
import * as _ from 'lodash'
import { ITaskQueue } from 'src/app/document/interfaces/ITaskQueue'
import { __Permissions } from 'src/app/core/permissionEnum/permissions'
import { FormFillsIn } from 'src/app/document/interfaces/FormFillsIn'
@Injectable({
    providedIn: 'root',
})
export class DocumentAIService extends BaseService {
    override get apiUrl(): string {
        return `${environment.API_Document}`
    }
    get apiCloudUrl(): string {
        return `${environment.API_URL}${environment.VERSION_URL}`
    }

    // get functionapiUrl(): string { return `${environment.functionsApiUrl}`;}
    apiDocument = 'https://documentai.openbots-dev.io'
    selectedTemplate: ITemplateLibrary | undefined
    caseTypeId: string = ''
    UnStructuredText = new Subject()
    activeTabIndex: number = 0
    checkListPermission = __Permissions.createCheckListTemplateItem
    filterDocumentName: EventEmitter<any> = new EventEmitter<any>()
    filterCaseType: EventEmitter<any> = new EventEmitter<any>()
    filterCaseTypeSubject = new BehaviorSubject<Object>([])
    CaseTypeName = new BehaviorSubject<Object>([])
    documentTaskListSubject = new BehaviorSubject<Object>([])
    MandatoryFieldCount = new BehaviorSubject<Object>([])
    CustomFieldCount = new BehaviorSubject<Object>([])
    OptionalFieldCount = new BehaviorSubject<Object>([])
    caseRecordId = new BehaviorSubject<Object>([])
    hideAction = new BehaviorSubject<Object>([])
    getSortNumber = new BehaviorSubject<Object>([])
    variableList = new BehaviorSubject<Object>([])
    tagList = new BehaviorSubject<Object>([])

    public casetypestatus = new BehaviorSubject<any[]>([])
    constructor(inject: Injector) {
        super(inject)
    }
    extractField(form: IChat) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.ExtractField}`,
            form
        )
    }
    postDocument(data?: any, options?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.SubmitDocumentsWithDetails}`,
            data
        )
    }
    SaveDocumentData(
        humanTaskId: string,
        documentId: string,
        documentObject: any
    ) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.SaveDocumentData}?humanTaskId=${humanTaskId}&documentId=${documentId}`,
            documentObject
        )
    }
    markDocumentAsVerified(
        humanTaskId: string,
        documentId: string,
        isVerified: boolean
    ) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.MarkDocumentAsVerified}?humanTaskId=${humanTaskId}&documentId=${documentId}&isVerified=${isVerified}`,
            null
        )
    }
    MarkDocumentAsSkipped(
        humanTaskId: string,
        documentId: string,
        isSkipped: boolean
    ) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.MarkDocumentAsSkipped}?humanTaskId=${humanTaskId}&documentId=${documentId}&isSkipped=${isSkipped}`,
            null
        )
    }
    postAssignTo(data: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.AssignTo}`,
            data
        )
    }
    getTaskStatus(taskId: string, options?: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetStatus}?humanTaskId=${taskId}`
        )
    }
    getQueuedTasks() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetQueueMessages}`
        )
    }
    getDocuments(taskId: string, options?: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetDocuments}?humanTaskId=${taskId}`
        )
    }
    getDocumentSignatures(taskId: string, documentSessionId: string) {
        const signatureUrl = `${this.apiUrl}/ExtractedFeature/GetAll?SessionIDFilter=${documentSessionId}&FeatureFilter=signature&MaxResultCount=1000`
        const documentsUrl = `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetDocuments}?humanTaskId=${taskId}`

        const signatureGet = this.http.get(signatureUrl)
        const documentsGet = this.http.get(documentsUrl)

        return forkJoin([signatureGet, documentsGet])
    }

    getDocumentData(taskId: string, docId: string, options?: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetDocumentData}?humanTaskId=${taskId}&documentId=${docId}`
        )
    }

    getDocumentImage(
        taskId: string,
        docId: string,
        pageNo: number,
        schemaNo: number,
        options?: any
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetPageImage}?humanTaskId=${taskId}&documentId=${docId}&pageNumber=${pageNo}&schemaNumber=${schemaNo}`
        )
    }

    GetTaskQueue() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}?SkipCount=0&MaxResultCount=1`
        )
    }
    GetQueueCount() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetTaskQueueCount}`
        )
    }
    GetTaskQueues(queue: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}${queue}`
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }
    GetTaskQueueForEdit(id: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetTaskQueueForEdit}?Id=${id}`
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }
    postTaskQueues(body: ITaskQueue) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.CreateOrEdit}`,
            body
        )
    }
    createPipeline(body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.createOrEdit}`,
            body
        )
    }
    getPipelineForEdit(id: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetPipelineForEdit}?Id=${id}`
        )
    }
    getPipelineCount() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetPipelineCount}`
        )
    }

    ////configuration api //

    DownloadTenantinfo(data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS_ENDPOINT.FORMS}${END_POINTS.FORMS_ENDPOINT.DownloadTenantInfo}`,
            data
        )
    }
    DownloadTenantInportFile(data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS_ENDPOINT.FORMS}${END_POINTS.FORMS_ENDPOINT.ImportTenantInfo}`,
            data
        )
    }
    //#region  Batch Api calls

    GetallBatch(odataQuery: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    let bathces: any[] = []
                    res.result.items.forEach((item: any) => {
                        let batch = {
                            name: item.documentBatch.name,
                            id: item.documentBatch.id,
                            status: item.documentBatch.status,
                            isLocked:
                                item.documentBatch.isLocked === true
                                    ? 'Yes'
                                    : 'No',
                            dataClass: undefined as any,
                        }
                        // if(batch.status =="Created")
                        // {
                        //     batch.dataClass='isStatusCreated';
                        // }
                        // else if(batch.status == "In-Progress")
                        // {
                        //   batch.dataClass='isStatusProgress';
                        // }
                        // else if(batch.status == "Completed")
                        // {
                        //   batch.dataClass='isStatusCompleted';
                        // }
                        // else if(batch.status == "Abandoned")
                        // {
                        //   batch.dataClass='isStatusAbandoned';
                        // }
                        // else if(batch.status == "Ready")
                        // {
                        //   batch.dataClass='isStatusReady';
                        // }

                        bathces.push(batch)
                    })
                    return { data: bathces, totalCount: res.result.totalCount }
                })
            )
    }

    getBatchById(id: string) {
        return this.http.get(`${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.GetDocumentBatchForView}?Id=${id}`)
    }
    createBatch(body: any) {
        return this.http.post(`${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.createOrEdit}`, body)
    }
    IsAllDocumentVerified(batchId: string) {
        let obj = {
            id: batchId,
        }
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.IsAllDocumentVerified}`,
            obj
        )
    }
    ReRunBatch(batchId: string) {
        let obj = {
            id: batchId,
        }
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.ReRunBatch}`,
            obj
        )
    }
    getBatchForEdit(id: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.GetDocumentBatchForEdit}?Id=${id}`
        )
    }

    deleteBatch(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.Delete}?Id=${Id}`
        )
    }

    multiDeleteBatch(body: IMultiSelect) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.MultipleDelete}`,
            body
        )
    }

    LockUnlockDocumentBatch(isLocked: string, batchId: string) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.LockUnlockDocumentBatch}?batchId=${batchId}&isLocked=${isLocked}`,
            ''
        )
    }

    UpdateDocumentBatchStatus(status: string, batchId: string) {
        return this.http.put(
            `${this.apiUrl}${END_POINTS.DocumentBatches.Batches}${END_POINTS.Batch_EndPoints.UpdateDocumentBatchStatus}?batchId=${batchId}&status=${status}`,
            ''
        )
    }

    GetAllBatchItems(odataQuery: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.DocumentBatchItems.DocumentBatchItems}${END_POINTS.BatchItems_EndPoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
    }

    GetAllBatchExportItems(odataQuery: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.Batch_EndPoints.DocumentBatchExports}${END_POINTS.Batch_EndPoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
    }

    downloadExportFile(id: string) {
        return this.http.post<any>(
            `${this.apiUrl}${END_POINTS.Batch_EndPoints.DocumentBatchExports}${END_POINTS.Batch_EndPoints.DownloadBatchFile}?id=${id}`,
            null
        )
    }

    downloadDocumentFile(id: string) {
        return this.http.get<any>(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetHumanTasksToExcel}?BatchIdFilter=${id}`
        )
    }
    postExportData(data: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Batch_EndPoints.DocumentBatchExports}${END_POINTS.Batch_EndPoints.CreateOrEdit}`,
            data
        )
    }

    getDocumentHistory(humanTaskId: string | undefined) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetHumanTaskHistory}?id=${humanTaskId}`
        )
    }

    deleteBatchItem(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.DocumentBatchItems.DocumentBatchItems}${END_POINTS.Batch_EndPoints.Delete}?Id=${Id}`
        )
    }

    //#endregion

    GetAllOrganizationUnitForLookupTable(queue: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAllOrganizationUnitForLookupTable}${queue}`
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }
    getTaskQueueToExcel() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetTaskQueuesToExcel}?Filter=&NameFilter=&OrganizationUnitDisplayNameFilter=`
        )
    }

    deleteTaskQueue(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.Delete}?Id=${Id}`
        )
    }
    GetTemplateLibraries(includeAdmin: boolean) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TemplateLibraries.TemplateLibraries}${END_POINTS.TemplateLibraries_EndPoints.Getall}?IncludeAdmin=${includeAdmin}&SkipCount=0&MaxResultCount=100`
        )
    }
    GetTemplateLibrariesOData(includeAdmin: boolean, queryString: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TemplateLibraries.TemplateLibraries}${END_POINTS.TemplateLibraries_EndPoints.Getall}${queryString}&IncludeAdmin=${includeAdmin}`
        )
    }

    trainForm(body: any) {
        return this.http
            .post(
                `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.TrainForm}?sourceHumanTaskID=${body.sourceHumanTaskID}&sourceExtractedFileID=${body.sourceExtractedFileID}&sourceFilePageNumber=${body.sourceFilePageNumber}`,
                body.formIds
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }

    GetAllTemplateLibraries() {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.TemplateLibraries.TemplateLibraries}${END_POINTS.TemplateLibraries_EndPoints.Getall}?SkipCount=0&MaxResultCount=1000`
            )
            .pipe(
                map((res: any) => {
                    let list: any[] = []
                    res.result?.items.forEach((lib: any) => {
                        list.push(lib.templateLibrary)
                    })
                    return list
                })
            )
    }
    GetTemplateLibraryForView(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TemplateLibraries.TemplateLibraries}${END_POINTS.TemplateLibraries_EndPoints.GetTemplateLibraryForView}?id=${id}`
        )
    }
    GetExtractedTables(
        ExtractedFileIdFilter: string,
        sessionId: string,
        pageNo: number
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTES_TABLES.ExtractedTables}${END_POINTS.EXTRACTES_TABLES_EndPoints.Getall}?SessionID=${sessionId}&SkipCount=0&MaxResultCount=500&FilePageNumberFilter=${pageNo}&ExtractedFileIdFilter=${ExtractedFileIdFilter}`
        )
    }
    GetExtractedTablesForView(tableId: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTES_TABLES.ExtractedTables}${END_POINTS.EXTRACTES_TABLES_EndPoints.GetExtractedTableForView}?id=${tableId}`
        )
    }
    GetExtractedTablesForUpdateView(library: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.EXTRACTES_TABLES.ExtractedTables}${END_POINTS.TemplateLibraries_EndPoints.CreateOrEdit}`,
            library
        )
    }
    GetExtractedSessions(sessionId: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTES_SESSIONS.ExtractedTables}${END_POINTS.EXTRACTES_SESSIONS_EndPoints.Getall}?SessionIDFilter=${sessionId}`
        )
    }
    createNewLibrary(library: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.TemplateLibraries.TemplateLibraries}${END_POINTS.TemplateLibraries_EndPoints.CreateOrEdit}`,
            library
        )
    }
    createNewForm(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.CreateOrEdit}`,
            form
        )
    }
    ImportFormJson(formData: FormData) {
        return this.http.post<any>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.ImportFormJson}`,
            formData
        )
    }

    deleteFormField(id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.Delete}?Id=${id}`
        )
    }
    exportToExcelField(id: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.ExportFormFields}?formId=${id}`,
            null
        )
    }
    createformField(field: any) {
        return this.http
            .post(
                `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.CreateOrEdit}`,
                field
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }
    getFormFieldForEdit(Id: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.GetFormFieldForEdit}?id=${Id}`
            )
            .pipe(
                map((res: any) => {
                    return res?.result?.formField as IFormFields
                })
            )
    }

    createBulkFields(fields: string[], formId: string) {
        return this.http
            .post(
                `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.BulkCreate}?formID=${formId}`,
                fields
            )
            .pipe(
                map((res: any) => {
                    let fields: IFormFields[] = []
                    res?.result.forEach((field: any) => {
                        ;(field.formField as IFormFields).isDeleted = false
                        fields.push(field.formField)
                    })
                    return fields
                })
            )
    }
    bulkCreateOrEditFields(fields: any[]) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.BulkCreateOrEdit}`,
            fields
        )
    }
    getAllFormFields(odataQuery: string, isGlobalTemplate: boolean, form: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    let fields: IFormFields[] = []

                    res?.result.items.forEach((field: any) => {
                        let obj: IFormFields
                        obj = field.formField
                        obj.canEdit = field.canEdit
                        obj.formName = field.formName

                        if (isGlobalTemplate) {
                            obj.menuOptionClass = 'disableall'
                        }
                        // else {
                        //   // if(field.canEdit == true && form.status == 'Published') {
                        //   //   obj.menuOptionClass='delete-disabled'
                        //   // }

                        // }

                        // form?.status != 'Published' && record.canEdit == true"

                        // if (obj.dataType == 1) {
                        //   obj.dataType = "string"
                        // } else if (obj.dataType == 2) {
                        //   obj.dataType = "any"
                        // } else if (obj.dataType == 3) {
                        //   obj.dataType = "integer"
                        // } else if (obj.dataType == 4) {
                        //   obj.dataType = "parent"
                        // }
                        fields.push(obj)
                    })
                    return { fields: fields, totalCount: res.result.totalCount }
                })
            )
    }

    getAllFormFieldsFillIn(odataQuery: string, isGlobalTemplate: boolean, form: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.FORM_FILL.FormFillIn}${END_POINTS.FORM_FIELDS_EndPoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    console.log(res)
                    let fields: FormFillsIn[] = []

                    res?.result.items.forEach((field: any) => {
                        let obj: FormFillsIn
                        obj = field.formFillIn
                        fields.push(obj)
                    })
                    return { fields: fields, totalCount: res.result.totalCount }
                })
            )
    }

    postFormFieldsFillIn(obj: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORM_FILL.FormFillIn}${END_POINTS.FORM_FILL.CreateOrEdit}`,
            obj
        )
    }

    getSiteList(){
        return this.http.get<any>(`${this.apiCloudUrl}Sites?$filter=status+eq+2&$top=1000&$skip=0`)
    }

    getFormLatetDraftVersion(url:string){
        return this.http.get<any>(`${this.apiCloudUrl}${url}`)
    }

    updateFormVersionJson(url:string, body:any){
        return this.http.put<any>(`${this.apiCloudUrl}${url}`, body)
    }

    deleteForm(url:string){
        return this.http.delete<any>(`${this.apiCloudUrl}${url}`)
    }


    getPublishedFormVersion(url:string){
        return this.http.get<any>(`${this.apiCloudUrl}${url}`)
    }

    getFormSubmissions(url:string){
        return this.http.get<any>(`${this.apiCloudUrl}${url}`)
    }

    SubmitFormData(url:string, body:any){
        return this.http.post<any>(`${this.apiCloudUrl}${url}`, body)
    }

    getFormListBaseOnSiteList(id:any){
        return this.http.get<any>(`${this.apiCloudUrl}Sites/${id}/Forms/View?$filter=status+eq+2&$top=1000&$skip=0`)
    }
    deleteFormFieldsFillIn(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.FORM_FILL.FormFillIn}${END_POINTS.FORM_FILL.Delete}?Id=${Id}`
        )
    }

    // /api/services/app/FormFields/GetFormFieldChildrenForEdit?
    getFormFieldChildrenForEdit(id: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.GetFormFieldChildrenForEdit}?Id=${id}`
            )
            .pipe(
                map((res: any) => {
                    let fields: IFormFields[] = []
                    res?.result.forEach((field: any) => {
                        let obj: IFormFields
                        obj = field.formField
                        // obj.formName = field.formName
                        fields.push(obj)
                    })
                    return { fields }
                })
            )
    }
    GetFormForEdit(id: string) {
        return this.http.get<Template>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.GetFormForEdit}?Id=${id}`
        )
    }
    GetFormCount() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.GetFormCount}`
        )
    }

    GetCaseTypeCount() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS_Types.CaseRecordType}${END_POINTS.CASE_RECORDS_Types.GetCaseRecordTypeCount}`
        )
    }
    //get Form Pages
    getFormPages(formId: string | null) {
        return this.http.get<any>(
            `${this.apiUrl}/FormPages/GetAll?FormId=${formId}`
        )
    }

    getClassification(url: string | null) {
        return this.http.get<any>(
            `${this.apiUrl}/FormClassifications/GetAll${url}`
        )
    }
    postClassification(body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FormClassifications.FormClassifications}${END_POINTS.Form_Classifications_EndPoints.CreateOrEdit}`,
            body
        )
    }
    deleteClassification(Id: any, body: any) {
        if(Id!="")
        return this.http.delete<any>(
            `${this.apiUrl}/FormClassifications/Delete?Id=${Id}`
            )
        else {
              return this.http.post(`${this.apiUrl}/FormClassifications/BulkDelete`, body )
        }
    }

    disableClassification(body: any) {

              return this.http.post(`${this.apiUrl}/FormClassifications/BulkDelete`, body )
    }

    getByFormId(id: string) {
        return this.http.get<any>(
            `${this.apiUrl}/FormFields/GetByFormId?formId=${id}`
        )
    }

    GetFormPageForEdit(id: string) {
        return this.http.get<any>(
            `${this.apiUrl}/FormPages/GetFormPageForEdit?Id=${id}`
        )
    }

    getTemplatePageImage(id: any, pageNumber: any) {
        return this.http.get<any>(
            `${this.apiUrl}/FormPages/GetFormPageImage?formId=${id}&pageNumber=${pageNumber}`
        )
    }

    uploadTemplateImage(body: any) {
        return this.http
            .post(`${this.apiUrl}/FormPages/UploadImage`, body)
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }

    getFieldBoxes(fieldName: any) {
        return this.http.get<any>(
            `${this.apiUrl}/FieldBoxes/GetAll?FormFieldNameFilter=${fieldName}`
        )
    }

    deleteFieldBox(boxId: any) {
        return this.http.delete<any>(
            `${this.apiUrl}/FieldBoxes/Delete?Id=${boxId}`
        )
    }

    mutipleCreateOrEditBox(body: FieldBox[]) {
        return this.http
            .post(`${this.apiUrl}/FieldBoxes/MutipleCreateOrEdit`, body)
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
    }

    getFormHistory(entityId: string | undefined) {
        return this.http.get<FormVersion>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.FormHistory}?entityId=${entityId}`
        )
    }

    getCaseRecordTypeHistory(entityId: string | undefined) {
        return this.http.get<FormVersion>(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS_Types.CaseRecordType}${END_POINTS.CASE_RECORDS_Types.CASERECORDSTypesGetHistory}?entityId=${entityId}`
        )
    }
    eventTypeHistory(entityId: string | undefined) {
        return this.http.get<FormVersion>(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.GetHistory}?entityId=${entityId}`
        )
    }
    getEventActions() {
        return this.http.get<any>(`${this.apiUrl}${'/FormFields/GetAll'}`)
    }
    getTemplateLibraries() {
        return this.http.get<any>(
            `${this.apiUrl}${'/TemplateLibraries/GetAll'}`
        )
    }

    //#region  BALANCE_CREDITS
    GetBalanceReport() {
        return this.http.get<any>(
            `${this.apiUrl}${END_POINTS.BALANCE_CREDITS.BalanceCredits}${END_POINTS.BALANCE_CREDITS_ENDPOINTS.GetBalanceReport}`
        )
    }
    GetAllBalance(odataQuery: string) {
        let url_ = `${this.apiUrl}${END_POINTS.BALANCE_CREDITS.BalanceCredits}${END_POINTS.BALANCE_CREDITS_ENDPOINTS.GetAll}`
        return this.http.get<any>(`${url_}${odataQuery || ''}`)
    }
    getAllFieldValidations() {
        let url_ = `${this.apiUrl}${END_POINTS.FIELD_VALIDATIONS.FieldValidations}${END_POINTS.FIELD_VALIDATIONS_EndPoints.GetAll}?SkipCount=0&MaxResultCount=100`
        return this.http.get<any>(`${url_}`)
    }

    // getBalanceCreditsToExcel(odataQuery: string) {
    //     let url_ = `${this.apiUrl}${END_POINTS.BALANCE_CREDITS.BalanceCredits}${END_POINTS.BALANCE_CREDITS_ENDPOINTS.GetBalanceCreditsToExcel}`;
    //     url_ = url_ + odataQuery || '';

    GetPipelineForView(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetAll}?IdFilter=${id}`
        )
    }

    deletefield(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.FORM_FIELDS.FormFields}${END_POINTS.FORM_FIELDS_EndPoints.Delete}?Id=${Id}`
        )
    }

    retireForm(formId: string) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.RetireForm}?formId=${formId}`,
            {}
        )
    }
    GetJSon(filename: string) {
        return this.http.get('../../assets/configs/' + filename)
    }

    getChatMessages(taskId: string) {
        return this.http.get(
            `${this.apiUrl}/DocumentChatQuestions/GetAll?humanTaskId=${taskId}`
        )
    }

    saveChatMessages(chatObj: any) {
        return this.http.post(
            `${this.apiUrl}/DocumentChatQuestions/CreateQuestion`,
            chatObj
        )
    }

    Chat(form: IChat) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.Chat}`,
            form
        )
    }
    // getschema(schema:string)
    // {
    //     return this.http.get(`../../assets/configs/${schema}.json`);
    // }
    //     let options_: any = {
    //         observe: "response",
    //         responseType: "blob",
    //         headers: new HttpHeaders({
    //             "Accept": "text/plain"
    //         })
    //     };

    //     return this.http.request("get", url_, options_).pipe(
    //         switchMap(
    //             (result)=>{
    //                 return this.downloadTempFile(result.result)
    //             }
    //         )
    //     );
    // }

    getAllFormForEvent(
        odataQuery: string,
        isGlobalTemplate: boolean,
        form: any
    ) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.REPORTS.TemplateLibrariesForEvent}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    let fields: IFormFields[] = []
                    res?.result.forEach((field: any) => {
                        let obj: any
                        obj = field
                        if (isGlobalTemplate) {
                            obj.menuOptionClass = 'disableall'
                        }
                        if (field.dataType == 1) {
                            obj.dataType = 'string'
                        } else if (field.dataType == 2) {
                            obj.dataType = 'any'
                        } else if (field.dataType == 3) {
                            obj.dataType = 'integer'
                        } else if (field.dataType == 4) {
                            obj.dataType = 'parent'
                        }
                        fields.push(obj)
                    })
                    return { fields: fields, totalCount: res.result.totalCount }
                })
            )
    }

    GetAllCaseRecordFieldses(queryString: string): Observable<any> {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORD_FIELDSES.CaseRecordFieldses}${END_POINTS.CASE_RECORD_FIELDSES_ENDPOINTS.GetAll}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    GetAllCaseRecordFieldsesForEvent(queryString: string): Observable<any> {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS.GetFormLayoutByEntityId}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    getAllCaseRcordTypeStatus(id: any, caseTypeId: any) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORDS_Types_status.CaseRecordTypeStatus}${END_POINTS.CASE_RECORDS_Types_status_EndPoints.getStatus}/${id}/${caseTypeId}`
        return this.http.get<any>(url)
    }
    getAllCaseRcordTypeNextStatus(id: any, pervioueState: any) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORDS_Types_status.CaseRecordTypeStatus}${END_POINTS.CASE_RECORDS_Types_status_EndPoints.GetAllNextStatus}?caseRecordTypeId=${id}&previousStatus=${pervioueState}`
        return this.http.get<any>(url)
    }
    getAllCaseTypeStatus(queryString: string = '') {
        let url =
            this.apiUrl + `/CaseRecordTypeStatus/GetAll?Sorting=displayOrder`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    getAllQueueEvent(queryString: string = '') {
        let url = this.apiUrl + `/EventQueues/GetAll`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    getAllQueueLookup(queryString: string = '') {
        let url = this.apiCloudUrl + `Queues/lookup`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    getAllQueueLookupAction() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}?MaxResultCount=1000`
        )
    }

    getCaseRecordTypeStatusMapping() {
        let url =
            this.apiUrl + `/Lookups/GetAll?Filter=CaseRecordTypeStatusMapping`
        return this.http.get<any>(url).pipe(
            map((res: any) => {
                let list: any[] = []
                if (res) {
                    res.result.items.forEach((item: any) => {
                        list.push({
                            id: item.lookup.id,
                            name: item.lookup.lookupValue,
                        })
                    })
                }
                return list
            })
        )
    }

    GetPendingCaseFields(queryString: string, caseRecordTypeId?: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORD_FIELDSES.CaseRecordFieldses}${END_POINTS.CASE_RECORD_FIELDSES_ENDPOINTS.GetPendingCaseFields}`
        if (queryString)
            url =
                url +
                queryString +
                (caseRecordTypeId
                    ? `&caseRecordTypeId=${caseRecordTypeId}`
                    : '')
        else
            url =
                url +
                (caseRecordTypeId
                    ? `?caseRecordTypeId=${caseRecordTypeId}`
                    : '')
        return this.http.get<any>(url)
    }
    GetAllCaseRecordFieldsCustomizationses(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES.CaseRecordFieldsCustomizationses}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS.GetAll}`
        if (queryString) {
            url = url + queryString
            return this.http.get<any>(url)
        }
    }

    caseRecordsFildsesChangeDisplayOrder(obj: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDSES.CaseRecordFieldses}${END_POINTS.CASE_RECORD_FIELDSES_ENDPOINTS.ChangeDisplayOrder}`,
            obj
        )
    }
    caseRecordsCustomisesChangeDisplayOrder(obj: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES.CaseRecordFieldsCustomizationses}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS.ChangeDisplayOrder}`,
            obj
        )
    }

    GetAllCaseRecordsType(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.GetAll}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }
    GetAllTags(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.TAGS.DocumentTags}${END_POINTS.Tags_EndPoints.GetAll}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    GetAllDocTags(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetTags}`
        url = url + queryString
        return this.http.get<any>(url)
    }

    addTags(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.Tags_EndPoints.AddTag}`,
            form
        )
    }

    deleteDocumentTag(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.Tags_EndPoints.DeleteTag}?tagId=${Id}`
        )
    }

    dismissedTags(Id: any,status:any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.Tags_EndPoints.DismissedTag}?tagId=${Id}&status=${status}`,
            ''
        )
    }

    QueueSubscriptionGetAll(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.QueueSubscription.QueueSubscription}${END_POINTS.QueueSubscription_EndPoint.GetAll}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }
    GetCaseRecordsType(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}/${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.caseRecordEdit}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }

    GetAllEvents(queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.GetAll}`
        if (queryString) url = url + queryString
        return this.http.get<any>(url)
    }
    createEditEvents(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.CreateOrEdit}`,
            form
        )
    }
    createEditCaseRecordsType(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.CreateOrEdit}`,
            form
        )
    }
    createTags(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.TAGS.DocumentTags}${END_POINTS.Tags_EndPoints.CreateOrEdit}`,
            form
        )
    }

    createEditWebHooks(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.QueueSubscription.QueueSubscription}${END_POINTS.QueueSubscription_EndPoint.CreateOrEdit}`,
            form
        )
    }
    createEditEvent(form: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.CreateOrEdit}`,
            form
        )
    }
    createNewVersion(id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.CreateNewVersion}?entityId=${id}`,
            body
        )
    }
    createUnPublished(typeId: any, id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.UnPublishedCaseType}?typeId=${typeId}&entityId=${id}`,
            body
        )
    }
    createNewVersionEvent(id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.CreateNewVersion}?entityId=${id}`,
            body
        )
    }
    casetypeRetired(id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.RetireCaseType}?id=${id}`,
            body
        )
    }
    EventeRetired(id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.RetireEvent}?eventId=${id}`,
            body
        )
    }
    eventRetired(id: any, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.RetireEvent}?eventId=${id}`,
            body
        )
    }
    publishCaseType(typeId: any, entityId: any, form: any) {
        const params = new HttpParams()
            .set('entityId', entityId)
            .set('typeId', typeId)

        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.PublishCaseType}`,
            form,
            { params }
        )
    }
    publishEvent(typeId: any, entityId: any, form: any) {
        const params = new HttpParams()
            .set('entityId', entityId)
            .set('eventId', typeId)

        return this.http.post(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.PublishEvent}`,
            form,
            { params }
        )
    }
    getCaseRecordsTypeforEdit(Id: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.caseRecordEdit}?Id=${Id}`
        )
    }
    RemoveCaseRecordsType(id: string) {
        let url = `${this.apiUrl}${END_POINTS.CASE_RECORD_TYPES.CaseRecordTypes}${END_POINTS.CASE_RECORD_TYPES_ENDPOINTS.Delete}`
        url = url + `?Id=${id}`
        return this.http.delete(url)
    }
    RemoveTagse(id: string) {
        let url = `${this.apiUrl}${END_POINTS.TAGS.DocumentTags}${END_POINTS.Tags_EndPoints.Delete}`
        url = url + `?Id=${id}`
        return this.http.delete(url)
    }
    RemoveWebHooks(id: string) {
        let url = `${this.apiUrl}${END_POINTS.QueueSubscription.QueueSubscription}${END_POINTS.QueueSubscription_EndPoint.Delete}`
        url = url + `?Id=${id}`
        return this.http.delete(url)
    }
    RemoveEvents(id: string) {
        let url = `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.Delete}`
        url = url + `?Id=${id}`
        return this.http.delete(url)
    }
    GetAllLookUpByTypeName(typeName: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.LOOK_UPS.Lookups}${END_POINTS.LOOK_UPS_EndPoints.GetLookup}`
        url = url + `?name=${typeName}`
        return this.http.get<any>(url)
    }
    GetOrganizationUnits() {
        return this.http.get<any>(
            this.apiUrl +
                `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.GetOrganizationUnits}`
        )
    }
    GetOrganizationUnitUsers(id: string, queryString: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.GetOrganizationUnitUsers}`
        if (queryString) {
            url = url + queryString + `&id=${id}`
        } else url = url + `?id=${id}`
        return this.http.get<any>(url)
    }
    FindUsers(
        organizationUnitId: number,
        maxResultCount: number,
        skipCount: number,
        filter: string | undefined
    ) {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.FindUsers}`
        // if (queryString) { url = url + queryString }
        let body = {
            organizationUnitId,
            maxResultCount,
            skipCount,
            filter,
        }
        return this.http.post<any>(url, body).pipe(
            map((res) => {
                let response: any[] = []
                res.result.items.forEach((item: any) => {
                    let member = {
                        fullName: item.fullName,
                        id: item.id,
                        emailAddress: item.emailAddress,
                        selected: false,
                    }
                    response.push(member)
                })
                return {
                    members: response,
                    totalCount: res.result.totalCount,
                }
            })
        )
    }
    getPageText(
        taskId: string,
        docId: string,
        pageNo: number,
        schemaNo: number,
        options?: any
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetPageText}?humanTaskId=${taskId}&documentId=${docId}&pageNumber=${pageNo}&schemaNumber=${schemaNo}`
        )
    }
    GetAllHumanTasksCount() {
        const queuCount = this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAll}?statusFilter=Created&MaxResultCount=10`
        )
        const inProgressCount = this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAll}?statusFilter=InProgress&MaxResultCount=10`
        )
        const errorsCount = this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAll}?statusFilter=Error&MaxResultCount=10`
        )
        return forkJoin([queuCount, inProgressCount, errorsCount])
    }
    GetHumanTaskAttachments(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAttachments}?humanTaskId=${id}`
        )
    }
    GetPendingTaskCount(name: string, taskQueueName: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetPendingTaskCount}?taskQueueNameFilter=${taskQueueName}&assignedTo=${name}`
        )
    }
    findNextTask(TaskObj: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.FindNextTask}`,
            TaskObj
        )
    }
    DownloadTaskAttachment(id: string) {
        return this.http.get<Blob>(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetDownloadFile}?id=${id}`,
            { observe: 'body', responseType: 'blob' as 'json' }
        )
    }
    multiDeleteTasks(body: IMultiSelect) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.MultipleDelete}`,
            body
        )
    }
    reAsignTasks(body: IMultiSelect) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.Reassign}`,
            body
        )
    }
    reAsignBatch(id: string, body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.DocumentBatchItems.DocumentBatchItems}${END_POINTS.DocumentBatchItems.AddTaskToBatch}?batchId=${id}`,
            body
        )
    }
    assignDueOn(body: IMultiSelect) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.AssignDueOn}`,
            body
        )
    }
    getHumanTaskForView(taskId: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetHumanTaskForView}?id=${taskId}`
        )
        // .pipe(
        //   map( (res:any)=>{
        //     res = res.result
        //     let task = res.humanTask;
        //     task.caseRecordName = res.caseRecordName;
        //     task.organizationUnitDisplayName = res.organizationUnitDisplayName;
        //     task.taskQueueName = res.taskQueueName;
        //     task.userName = res.userName;
        //     return task
        //   })
        // )
    }
    saveHumanTask(obj: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.CreateOrEdit}`,
            obj
        )
    }

    getChecklistTemplate(queryString: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.Checklist_Templates.GetAll}${queryString}`
        )
    }

    getAllChecklistForms(entityId: string | undefined) {
        let url_ = `${
            this.apiUrl
        }${'/Forms/GetAll?StatusFilter=Published&IsGlobalTemplate=false&MaxResultCount=10000&Sorting=name+asc'}`
        if (entityId !== undefined && entityId !== null && entityId !== '')
            url_ += '&entityId=' + encodeURIComponent('' + entityId) + '&'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.get<any>(url_)
    }

    getCheckExpressionFieldsList(entityId: string) {
        let formsUrl = `${this.apiUrl}${END_POINTS.Checklist_Templates.GetAllTenantForms}`
        if (entityId != null || entityId != undefined) {
            formsUrl = formsUrl + '?entityId=' + entityId
        }
        let forms = this.http.get<any>(formsUrl)

        let layouts = this.http.get<any>(
            `${this.apiUrl}/FormFields/GetAllByFormEntityId?entityId=${entityId}`
        )
        let functions = this.http.get('../../assets/functions.json')
        return forkJoin([forms, layouts, functions]).pipe(
            map((res) => {
                let forms = res[0].result as IForm[]
                let layoutsList = res[1].result as any[]
                let func = res[2] as any[]
                let list = [] as ICheckExpressionFieldLookUp[]
                if (forms[0].jsonSchema != null) {
                    let schema = this.convertSchemaIntoObject(
                        forms[0].jsonSchema
                    ) as ISchema[]
                    schema.forEach((lst) => {
                        list.push({
                            name: lst.Title,
                            label: 'template.' + lst.Title,
                            value: 'template.' + lst.Key,
                            type: lst.Type,
                            controlType: 'variable',
                        })
                    })
                }

                layoutsList.forEach((lst) => {
                    let fieldType = ''

                    if (lst.dataType == 1) {
                        fieldType = 'string'
                    } else if (lst.dataType == 2) {
                        fieldType = 'any'
                    } else if (lst.dataType == 3) {
                        fieldType = 'integer'
                    } else if (lst.dataType == 4) {
                        fieldType = 'parent'
                    }

                    list.push({
                        name: lst.name,
                        label: lst.title,
                        value: lst.name,
                        type: fieldType,
                        controlType: 'variable',
                    })
                })
                func.forEach((f: any) => {
                    let obj = {} as ICheckExpressionFieldLookUp
                    obj = JSON.parse(JSON.stringify(f))
                    obj.label = 'f(X) ' + f.name
                    obj.value = f.name
                    list.push(obj)
                })
                return list
            })
        )
    }
    deleteCaseRecord(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.DeleteInternalCases}?id=${Id}`
        )
    }

    getAllChecklistTemplates(Id: string, query: string = '') {
        if (query != '') {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.ChecklistTemplates}${END_POINTS.CHECK_LIST_TEMPLATE_ENDPOINT.GetAll}?${query}`
            )
        } else {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.ChecklistTemplates}${END_POINTS.CHECK_LIST_TEMPLATE_ENDPOINT.GetAll}?CaseRecordTypeId=${Id}`
            )
        }
    }

    getAllChecklist(Id: string, query: string = '') {
        if (query != '') {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.Checklist}${END_POINTS.CHECK_LIST_TEMPLATE_ENDPOINT.GetAll}?${query}`
            )
        } else {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.Checklist}${END_POINTS.CHECK_LIST_TEMPLATE_ENDPOINT.GetAll}?CaseRecordId=${Id}`
            )
        }
    }

    getpendingCheckList(query: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.ChecklistTemplates}${END_POINTS.CHECK_LIST_ENDPOINT.Pending}${query}`
        )
    }

    AttachToCaseCheckList(body: any, caseId: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.AttachToCase}?caseId=${caseId}`,
            body
        )
    }
    getAllChecklists(query: string = '') {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.GetAll}${query}`
        )
    }
    getAllChecklistsItems(query: string = '') {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.GetChecklistItems}${query}`
        )
    }

    getAllChecklistTemplateItems(query: string = '') {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE_ITEM.ChecklistTemplateItems}${END_POINTS.CHECK_LIST_TEMPLATE_ITEM_ENDPOINT.GetAll}${query}`
        )
    }

    getDocumentList(query: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetDocumentList}${query}`
        )
    }
    GetChecklistTemplateForView(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CHECK_LIST_TEMPLATE.ChecklistTemplates}${END_POINTS.CHECK_LIST_TEMPLATE_ENDPOINT.GetChecklistTemplateForView}?id=${id}`
        )
    }
    AttachToCase(body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.AttachToCase}`,
            body
        )
    }
    UpdateCaseListFromCase(body: any) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.UpdateCaseListFromCase}`,
            body
        )
    }
    postCreateOrEdit(data?: any, options?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.CreateOrEdit}`,
            data
        )
    }
    postCreateOrEditCaseFieldses(id: any, data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDSES.CaseRecordFieldses}${END_POINTS.CASE_RECORD_FIELDSES_ENDPOINTS.CreateOrEdit}?CaseRecordTypeIdFilter=${id}`,
            data
        )
    }
    postCreateOrEditCaseRecordCustomizationses(
        id: any,
        data?: any
    ): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES.CaseRecordFieldsCustomizationses}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS.CreateOrEdit}?CaseRecordTypeIdFilter=${id}`,
            data
        )
    }
    postCreateOrEditCaseRecordCustomizationsesOptional(
        data?: any
    ): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES.CaseRecordFieldsCustomizationses}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS.CreateOrEdit}`,
            data
        )
    }

    createCaseStatus(data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${'/CaseRecordTypeStatus/CreateOrEdit'}`,
            data
        )
    }

    deleteCaseStatus(id: string): Observable<any> {
        return this.http.delete(
            `${this.apiUrl}${'/CaseRecordTypeStatus/Delete?Id='}${id}`
        )
    }

    deleteQueueEvent(id: string): Observable<any> {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.EventsQueue.EventQueues}${END_POINTS.Events_EndPoints.Delete}?Id=${id}`
        )
    }

    deleteCaseRecordCustomizationses(id: string): Observable<any> {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES.CaseRecordFieldsCustomizationses}${END_POINTS.CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS.Delete}?Id=${id}`
        )
    }

    getCaseRecordForView(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS_Types.CaseRecordType}${END_POINTS.CASE_RECORDS_EndPoints.GetCaseRecordForView}?id=${id}`
        )
    }

    getCaseRecordFieldes(query: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CaseRecordFieldses.CaseRecordFieldses}${END_POINTS.CaseRecordFieldses_EndPoint.GetAll}${query}`
        )
    }

    getCaseRecordTypeForEdit(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS_Types.CaseRecordType}${END_POINTS.CASE_RECORDS_EndPoints.GetCaseRecordForEdit}?id=${id}`
        )
    }

    getCaseRecordForEdit(id: string) {
        return this.http.get(
            `${this.apiUrl}/CaseRecords${END_POINTS.CASE_RECORDS_EndPoints.CaseRecordForEdit}?id=${id}`
        )
    }

    createOrEditQueueEvent(data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.EventsQueue.EventQueues}${END_POINTS.Events_EndPoints.CreateOrEdit}`,
            data
        )
    }
    getEventForEdit(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.Events.Event}${END_POINTS.Events_EndPoints.GetEventForEdit}?Id=${id}`
        )
    }
    getfunctions() {
        return this.http.get<ICheckExpressionFieldLookUp[]>(
            '../../assets/functions.json'
        )
    }

    convertSchemaIntoObject(schemaString: string): ISchema[] {
        var formList: any[] = []
        var schema = JSON.parse(schemaString)
        while (typeof schema === 'string') {
            //To verify documentDetail is still string event after parse
            schema = JSON.parse(schema)
        }
        if (Object.keys(schema?.properties).length != 0) {
            Object.keys(schema?.properties).forEach((element) => {
                let schemaObject = {} as ISchema
                schemaObject.Key = element
                if (schema.properties[element]['Visibility']) {
                    schemaObject.Visibility =
                        schema.properties[element]['Visibility']
                }
                if (schema.properties[element]['Validation']) {
                    schemaObject.Validation =
                        schema.properties[element]['Validation']
                }
                if (schema.properties[element]['pageNumber']) {
                    schemaObject.pageNumber =
                        schema.properties[element]['pageNumber']
                }
                if (schema.properties[element]['SortOrder']) {
                    schemaObject.SortOrder =
                        +schema.properties[element]['SortOrder']
                }
                if (schema.properties[element]['title']) {
                    schemaObject.Title = schema.properties[element]['title']
                }
                if (schema.properties[element]['type']) {
                    schemaObject.Type = schema.properties[element]['type']
                    if (schemaObject.Type == 'array') {
                        schemaObject.items = this.convertSchemaIntoObject(
                            JSON.stringify(schema.properties[element]['items'])
                        )
                    }
                }
                if (schema.properties[element]['Required']) {
                    schemaObject.Required =
                        schema.properties[element]['Required']
                }
                if (schema.properties[element]['Sensitivity']) {
                    schemaObject.Sensitivity =
                        schema.properties[element]['Sensitivity']
                }
                if (schema.properties[element]['MinimumLength']) {
                    schemaObject.MinimumLength =
                        schema.properties[element]['MinimumLength']
                }
                if (schema.properties[element]['MaximumLength']) {
                    schemaObject.MaximumLength =
                        schema.properties[element]['MaximumLength']
                }
                if (schema.properties[element]['Pattern']) {
                    schemaObject.Pattern = schema.properties[element]['Pattern']
                }
                if (schema.properties[element]['Description']) {
                    schemaObject.Description =
                        schema.properties[element]['Description']
                }
                if (schema.properties[element]['recognitionType']) {
                    schemaObject.recognitionType =
                        schema.properties[element]['recognitionType']
                }
                if (schema.properties[element]['isCaseLookup']) {
                    schemaObject.isCaseLookup =
                        schema.properties[element]['isCaseLookup']
                }
                if (schema.properties[element]['hasCaseFieldMapping']) {
                    schemaObject.hasCaseFieldMapping =
                        schema.properties[element]['hasCaseFieldMapping']
                }
                if (schema.properties[element]['caseFieldID']) {
                    schemaObject.caseFieldID =
                        schema.properties[element]['caseFieldID']
                }

                //TODO - Removed above once all the elements are pascel casing
                if (schema.properties[element]['visibility']) {
                    schemaObject.Visibility =
                        schema.properties[element]['visibility']
                }
                if (schema.properties[element]['validation']) {
                    schemaObject.Validation =
                        schema.properties[element]['validation']
                }
                if (schema.properties[element]['sortOrder']) {
                    schemaObject.SortOrder =
                        +schema.properties[element]['sortOrder']
                }
                if (schema.properties[element]['title']) {
                    schemaObject.Title = schema.properties[element]['title']
                }
                if (schema.properties[element]['type']) {
                    schemaObject.Type = schema.properties[element]['type']
                }
                if (schema.properties[element]['required']) {
                    schemaObject.Required =
                        schema.properties[element]['required']
                }
                if (schema.properties[element]['sensitivity']) {
                    schemaObject.Sensitivity =
                        schema.properties[element]['sensitivity']
                }
                if (schema.properties[element]['minimumLength']) {
                    schemaObject.MinimumLength =
                        schema.properties[element]['minimumLength']
                }
                if (schema.properties[element]['maximumLength']) {
                    schemaObject.MaximumLength =
                        schema.properties[element]['maximumLength']
                }
                if (schema.properties[element]['pattern']) {
                    schemaObject.Pattern = schema.properties[element]['pattern']
                }
                if (schema.properties[element]['description']) {
                    schemaObject.Description =
                        schema.properties[element]['description']
                }
                if (schema.properties[element]['pageNumber'] == undefined) {
                    schemaObject.pageNumber = 1
                }

                formList.push(schemaObject)
            })

            if (formList[0].SortOrder) {
                return _.sortBy(formList, 'SortOrder')
            }
        }
        return formList
    }

    getChecklistTemplatebyId(id: number) {
        // /api/services/app/ChecklistTemplateItems/GetAllByTemplateId
        let url = `${this.apiUrl}${END_POINTS.Checklist_Templates.Get_Checklist}?Id=${id}`
        return this.http.get(url)
    }
    checklistItems(Id: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.ChecklistItem.ChecklistItems}${END_POINTS.ChecklistItem_EndPoints.GetAll}?nameFilter=${Id}`
        )
    }
    checklistitemsCreateOrEdit(body: any) {
        let url_ = `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.UpdateChecklistItemStatus}`
        return this.http.post<any>(url_, body)
    }
    createOrEditChecklist(body: any): Observable<any> {
        let url_ = `${this.apiUrl}${END_POINTS.Checklist_Templates.Create_Edit}`
        return this.http.post<any>(url_, body)
    }

    exportChecklist() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.Checklist_Templates.ExportExcel}`
        )
    }

    deleteChecklistTemplate(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.Checklist_Templates.Delete}?Id=${Id}`
        )
    }

    deleteTempLibrary(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.TemplateLibraries.DeleteTemplateLibrary}?Id=${Id}`
        )
    }
    deletePipeline(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.delete}?Id=${Id}`
        )
    }

    downloadchecklistTempFile(file: any) {
        const url = `${environment.API_Document_Url}/File/DownloadTempFile?fileType=${file.fileType}&fileToken=${file.fileToken}&fileName=${file.fileName}`
        return this.http.post(url, null, {
            responseType: 'arraybuffer',
            observe: 'response',
        })
    }

    GetAllDocumentNameFilter(Id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAllDocument}?nameFilter=${Id}`
        )
    }
    GetAllDocumentProcessingCount() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.ProcessingCount}`
        )
    }
    GetAllHumanTAsk(odataUrl: string) {
        let currentDate = new Date()
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAll}${odataUrl}`
            )
            .pipe(
                map((res: any) => {
                    let tasks: HumanTask[] = []
                    res?.result?.items.forEach((item: any) => {
                        let task = {} as HumanTask
                        task = item.humanTask
                        task.selected = false
                        task.taskQueueName = item.taskQueueName
                        task.organizationUnitDisplayName =
                            item.organizationUnitDisplayName
                        task.caseRecordName = item.caseRecordName
                        // task.actionClass='disabletimeline';
                        let date = new Date(item.humanTask.createdOn + 'Z')
                        let days = differenceInDays(currentDate, date)
                        let hours = differenceInHours(currentDate, date) % 24
                        let minutes =
                            differenceInMinutes(currentDate, date) % 60
                        task.timeStatus_tillnow = ''
                        if (days <= 0 && hours <= 0) {
                            if (minutes <= 0) {
                                task.timeStatus_tillnow = 'Just Now'
                            } else {
                                task.timeStatus_tillnow =
                                    minutes + ' Min(s) ago'
                            }
                        } else {
                            if (days > 0) {
                                task.timeStatus_tillnow =
                                    task.timeStatus_tillnow + days + ' Day(s) '
                            }
                            if (hours > 0) {
                                task.timeStatus_tillnow =
                                    task.timeStatus_tillnow +
                                    hours +
                                    ' Hour(s) '
                            }
                            if (minutes > 0) {
                                task.timeStatus_tillnow =
                                    task.timeStatus_tillnow +
                                    minutes +
                                    ' Min(s) ago'
                            }
                        }

                        if (hours >= 1 || days > 0) {
                            task.canDeletedAfterHour = true
                        } else {
                            task.canDeletedAfterHour = false
                        }

                        tasks.push(task)
                    })
                    return { tasks: tasks, totalCount: res?.result.totalCount }
                })
            )
    }
    GetLookupsForCreateDocument() {
        let tasQueues = this.http
            .get(
                `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}?MaxResultCount=1000`
            )
            .pipe(
                map((res: any) => {
                    return res.result.items
                })
            )
        let pipelines = this.http
            .get(
                `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetAll}?MaxResultCount=1000`
            )
            .pipe(
                map((res: any) => {
                    let pipiline: any[] = []
                    res?.result?.items?.forEach((p: any) => {
                        pipiline.push(p.pipeline)
                    })
                    return pipiline
                })
            )
        let AssignTo = this.http
            .get(
                `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAllUserForTableDropdown}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
        var organizationUnit = this.http
            .get<any>(
                this.apiUrl +
                    `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.GetOrganizationUnits}`
            )
            .pipe(
                map((res: any) => {
                    ;(res.result.items as any[]).unshift({
                        id: 0,
                        displayName: 'Unassigned',
                    })
                    return res.result.items
                })
            )
        return forkJoin([tasQueues, AssignTo, pipelines, organizationUnit])
    }

    GetCount(caseRecordId: string) {
        let taskCount = this.http
            .get(
                `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.Count}/${caseRecordId}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
        let documentCount = this.http
            .get(
                `${this.apiUrl}${END_POINTS.Extracted_Documents.ExtractedDocuments}${END_POINTS.Extracted_Documents_EndPoints.Count}/${caseRecordId}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )

        // let ChecklistCount;
        // if (!this.checkListPermission ) {
        let ChecklistCount = this.http
            .get(
                `${this.apiUrl}${END_POINTS.CHECK_LIST.Checklists}${END_POINTS.CHECK_LIST_ENDPOINT.GetCheckListCount}?caseRecordId=${caseRecordId}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
        // } else {
        //     ChecklistCount = 0;
        // }

        return forkJoin([taskCount, documentCount, ChecklistCount])
    }
    //   GetAllLookUpsForDoumentsList(): Observable<any[]> {
    //     var queuelist = this.http.get<any>(`${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}`)
    //       .pipe(map((res) => {
    //         let list: any[] = [];
    //         if (res) {
    //           res.result.items.forEach((item: any) => {
    //             list.push(
    //               {
    //                 id: item.taskQueue.id,
    //                 name: item.taskQueue.name
    //               });
    //           });
    //         }
    //         return this.http.post<any>(url, body).pipe(map((res)=>{
    //             let response:any[]=[];
    //             res.result.items.forEach((item:any) => {
    //                 let member={
    //                     name:item.name,
    //                     value:item.value,
    //                     selected:false
    //                 }
    //                 response.push(member);
    //             });
    //             return {
    //                 members:,
    //                 totalCount:res.result.totalCount
    //             };
    //         }));
    //     }
    AddUsersToOrganizationUnit(userIds: number[], organizationUnitId: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.AddUsersToOrganizationUnit}`
        // if (queryString) { url = url + queryString }
        let body = {
            organizationUnitId,
            userIds,
        }
        return this.http.post<any>(url, body)
    }
    CreateOrganizationUnit(data: any): Observable<any> {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.CreateOrganizationUnit}`
        return this.http.post(url, data)
    }
    UpdateOrganizationUnit(data: any): Observable<any> {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.UpdateOrganizationUnit}`
        return this.http.put(url, data)
    }
    DeleteOrganizationUnit(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.DeleteOrganizationUnit}?Id=${Id}`
        )
    }
    RemoveUserFromOrganizationUnit(UserId: string, organizationUnitId: string) {
        let url =
            this.apiUrl +
            `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.RemoveUserFromOrganizationUnit}`
        return this.http.delete(
            `${url}?UserId=${UserId}&organizationUnitId=${organizationUnitId}`
        )
    }
    // RemoveUserFromOrganizationUnit
    //#endregion
    createNewFormVersion(
        formdata: FormData,
        entityId: string
    ): Observable<any> {
        return this.http.post<any>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.CreateFormVersion}?entityId=${entityId}`,
            formdata
        )
    }

    importTemplate(entityId: string, importObj: any) {
        return this.http.post<FormVersion>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.ImportInMyTemplate}?entityId=${entityId}`,
            importObj
        )
    }

    exportToFile(formId: string) {
        // let options_: any = {
        //   observe: "response",
        //   responseType: "blob",
        //   headers: new HttpHeaders({
        //     "Accept": "text/plain"
        //   })
        // };
        return this.http.get<FormVersion>(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.ExportFormFile}?formId=${formId}`
        )
    }

    getValueSets(name:string='',Id:string)
    {
        if (name != '')
        {
          return  this.http.get<any>(`${this.apiCloudUrl}Data/Lookups/GetAllByName?$nameFilter=${name}`)
        }
        else if (Id != null || Id!='')
        {
          return  this.http.get<any>(`${this.apiCloudUrl}Data/Lookups/GetAllByName?$idFilter=${Id}`)
        }
        else {
          return  this.http.get<any>(`${this.apiCloudUrl}Data/Lookups/GetAllByName`)
        }
    }

    getLookupValueSets(lookupId: string){
        return  this.http.get<any>(`${this.apiCloudUrl}Data/Lookup/${lookupId}/LookupValues?$orderby=sequenceOrder+asc&$top=1000&$skip=0`)
    }
    getLookUps_For_Add_FormFields() {
        let validation = this.http
            .get(
                `${this.apiUrl}${END_POINTS.FIELD_VALIDATIONS.FieldValidations}${END_POINTS.FIELD_VALIDATIONS_EndPoints.GetAll}`
            )
            .pipe(
                map((res: any) => {
                    var validation: IFieldValidation[] = []
                    res.result.items.forEach((item: any) => {
                        validation.push(item.fieldValidation)
                    })
                    return validation
                })
            )
        let casefields = this.http
            .get<ICaseRecordFields[]>(
                `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.GetFormLayout}`
            )
            .pipe(
                map((res: any) => {
                    return res?.result
                })
            )
        return forkJoin([validation, casefields])
    }
    getFormLayout(caseTypeId: string = '') {
        if (caseTypeId == '') {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.GetFormLayout}`
            )
        } else {
            return this.http.get(
                `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.GetFormLayout}?caseTypeId=${caseTypeId}`
            )
        }
    }
    UpdateCaseRecordStatus(status?: any, body?: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.put(
            `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.UpdateCaseRecordStatus}?status=${status}`,
            body,
            { headers: headers }
        )
    }

    GetAllForms(entityId?: string) {
        let url: string
        if (entityId != null || entityId != undefined) {
            url = `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetAllForms}`
            url = url + `?entityId=${entityId}`
        } else {
            url = `${this.apiUrl}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE.DocumentProcessingEngineService}${END_POINTS.DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints.GetAllTenantForms}`
        }
        return this.http.get(`${url}`)
    }

    GetAllCaseRecords(odataUrl: string) {
        return this.http
            .get(
                `${this.apiUrl}${
                    END_POINTS.CASE_RECORDS.CaseRecords
                }${'/GetAll'}${odataUrl}`
            )
            .pipe(
                map((res: any) => {
                    var caseRecords: any[] = []
                    var count = res.result.totalCount
                    res.result.items.forEach((element: any) => {
                        caseRecords.push(element.caseRecord)
                    })
                    return { caseRecords: caseRecords, totalCount: count }
                })
            )
    }

    GetAllInternalCases(odataUrl: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.CASE_RECORDS.CaseRecords}${END_POINTS.CASE_RECORDS_EndPoints.GetAllInternalCases}${odataUrl}`
            )
            .pipe(
                map((res: any) => {
                    var caseRecords: any[] = []
                    var count = res.result.totalCount
                    res.result.items.forEach((element: any) => {
                        caseRecords.push(element.caseRecord)
                    })
                    return { caseRecords: caseRecords, totalCount: count }
                })
            )
    }
    publishForm(body: any) {
        const params = new HttpParams()
            .set('formId', body.formId)
            .set('entityId', body.entityId)
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.PublishForm}`,
            {},
            { params }
        )
    }

    PublishFormToTrain(body: any) {
        const params = new HttpParams()
            .set('formId', body.formId)
            .set('entityId', body.entityId)
            .set('sourceHumanTaskID', body.sourceHumanTaskID)
            .set('sourceExtractedFileID', body.sourceExtractedFileID)
            .set('sourceFilePageNumber', body.sourceFilePageNumber)
        return this.http.post(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.PublishFormToTrain}`,
            {},
            { params }
        )
    }

    deleteTask(Id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.Delete}?Id=${Id}`
        )
    }
    ReProcessTask(ids: string[]) {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.ReProcessTask}?createNewTask=true`,
            ids
        )
    }
    GetAllLookUpsForDoumentsList(): Observable<any[]> {
        var queuelist = this.http
            .get<any>(
                `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}/GetAllQueues`
            )
            .pipe(
                map((res) => {
                    let list: any[] = []
                    if (res) {
                        res.result.forEach((item: any) => {
                            list.push({
                                id: item.id,
                                name: item.name,
                            })
                        })
                    }
                    return list
                })
            )
        var statuslist = this.http
            .get<any>(
                this.apiUrl +
                    `${END_POINTS.LOOK_UPS.Lookups}${END_POINTS.LOOK_UPS_EndPoints.GetAll}?Filter=HumanTaskStatus`
            )
            .pipe(
                map((res: any) => {
                    let list: any[] = []
                    if (res) {
                        res.result.items.forEach((item: any) => {
                            list.push({
                                id: item.lookup.id,
                                name: item.lookup.lookupValue,
                            })
                        })
                    }
                    return list
                })
            )
        var assigntolist = this.http
            .get<IUserFilter[]>(
                `${this.apiUrl}${END_POINTS.HUMAN_TASK.HumanTask}${END_POINTS.HUMAN_TASKS_EndPoints.GetAllUserForTableDropdown}`
            )
            .pipe(
                map((res: any) => {
                    ;(res.result as any[]).unshift({
                        id: 0,
                        displayName: 'Unassigned',
                    })
                    return res.result
                })
            )
        var organizationUnit = this.http
            .get<any>(
                this.apiUrl +
                    `${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.GetOrganizationUnits}`
            )
            .pipe(
                map((res: any) => {
                    ;(res.result.items as any[]).unshift({
                        id: 0,
                        displayName: 'Unassigned',
                    })
                    return res.result.items
                })
            )
        return forkJoin([queuelist, assigntolist, statuslist, organizationUnit])
    }
    getStatus() {
        return this.http
            .get<any>(
                this.apiUrl +
                    `${END_POINTS.LOOK_UPS.Lookups}${END_POINTS.LOOK_UPS_EndPoints.GetAll}?Filter=HumanTaskStatus`
            )
            .pipe(
                map((res: any) => {
                    let list: any[] = []
                    if (res) {
                        res.result.items.forEach((item: any) => {
                            list.push({
                                id: item.lookup.id,
                                name: item.lookup.lookupValue,
                            })
                        })
                    }
                    return list
                })
            )
    }
    //widget APIS
    getOrganizationUnitUsers(orgId: number) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.ORGANIZATION_UNIT.OrganizationUnit}${END_POINTS.ORGANIZATION_UNIT_EndPoints.GetOrganizationUnitUsers}?Id=${orgId}`
            )
            .pipe(
                map((res: any) => {
                    let list: any[] = []
                    res.result.items.forEach((user: any) => {
                        let team = {
                            id: user.id,
                            displayName: user.name + ' ' + user.surname,
                        } as any
                        list.push(team)
                    })
                    list.splice(0, 0, {
                        displayName: 'Unassigned',
                        id: 0,
                    } as any)
                    return list
                })
            )
    }
    GetAllLookUpsForHumanTaskPage(): Observable<any> {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.TASK_QUEUES.TaskQueues}${END_POINTS.TASK_QUEUES_EndPoints.GetAll}?MaxResultCount=1000`,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }

    GetAgeOfPendingTasks(taskQueueId: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetAgeOfPendingTasks}` +
                taskQueueId,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    GetAgeOfCompletedTasks(taskQueueId: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetAgeOfCompletedTasks}` +
                taskQueueId,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    getSubscriptionStatus() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetSubscriptionStatus}`,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    GetTaskCountByStatus(taskQueueId: any) {
        // /api/services/app${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}/GetTaskCountByStatus
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetTaskCountByStatus}` +
                taskQueueId,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
        //   .pipe(catchError((error) => { return throwError(error); }))
    }
    GetCompletedTaskByUser() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetCompletedTasksByUser}`,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    getOrganizationStatus() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.DASHBOARD_WIDGETS.DashboardWidgets}${END_POINTS.DASHBOARD_WIDGETS_EndPoints.GetOrganizationStatus}`,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    getActiveTenants() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.REPORTS.ActiveTenent}`,
            { headers: new HttpHeaders({ Accept: 'text/plain' }) }
        )
    }
    GetBalanceDebitsGraph(
        MinTransactionDateTimeFilter: any,
        MaxTransactionDateTimeFilter: any
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.BALANCE_DEBITS.BalanceDebits}${END_POINTS.GET_BALANCE_DEBITS_EndPoints.GetBalanceDebitsGraph}` +
                '?' +
                'MinTransactionDateTimeFilter' +
                '=' +
                MinTransactionDateTimeFilter +
                '&' +
                'MaxTransactionDateTimeFilter=' +
                MaxTransactionDateTimeFilter
        )
    }
    GetBalanceDebitsToExcel(
        MinTransactionDateTimeFilter: any,
        MaxTransactionDateTimeFilter: any
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.REPORTS_EXCEL.GetBalanceDebitsToExcel}` +
                MinTransactionDateTimeFilter +
                '&' +
                'MaxTransactionDateTimeFilter=' +
                MaxTransactionDateTimeFilter
        )
    }
    getOrganizationUnits() {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.REPORTS.OrganizationUnits}`
        )
    }
    GetPendingTaskByUser(odataQuery: string) {
        return this.http
            .get(`${this.apiUrl}${END_POINTS.REPORTS.PendingTask}${odataQuery}`)
            .pipe(
                map((res: any) => {
                    return res
                })
            )
    }
    templateGetAll(
        odataQuery: string,
        IsGlobalTemplate: boolean,
        pageName?: string
    ) {
        if (pageName != 'eventDetails') {
            odataQuery = odataQuery + '&IsGlobalTemplate=' + IsGlobalTemplate
        }
        odataQuery = odataQuery + '&IsGlobalTemplate=false'
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.REPORTS.TemplateLibraries}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    let templates: any[] = []
                    res.result.items.forEach((item: any) => {
                        let template = {
                            templateLibraryName: item.templateLibraryName,
                            name: item.form.name,
                            edition: item.form.edition,
                            status: item.form.status,
                            id: item.form.id,
                            entityId: item.form.entityId,
                            formVersion: item.form.formVersion,
                            tenantId: item.form.tenantId,
                            canDelete: item.canDelete,
                            canEdit: item.canEdit,
                            canRetire: item.canRetire,
                            canPublish:
                                item.form.status.trim().toLowerCase() ===
                                'published'
                                    ? false
                                    : true,
                            menuOptionClass: undefined as any,
                            dataClass: undefined as any,
                            actionClass: undefined as any,
                        }
                        if (
                            item.form.status.trim().toLowerCase() === 'retired'
                        ) {
                            template.canDelete = false
                            template.canPublish = false
                            template.canRetire = false
                        }
                        if (
                            item.form.status.trim().toLowerCase() ===
                            'published'
                        ) {
                            template.canDelete = false
                        }
                        if (
                            template.canPublish == false &&
                            template.canDelete == false &&
                            template.canRetire == false
                        ) {
                            template.menuOptionClass =
                                ' delete-disabled publish-disabled retire-disabled '
                            template.actionClass =
                                ' delete-disabled publish-disabled retire-disabled '
                        } else {
                            if (template.canDelete == false) {
                                template.menuOptionClass = 'delete-disabled'
                                template.actionClass = 'delete-disabled'
                            }
                            if (template.canPublish == false) {
                                if (template.menuOptionClass == undefined) {
                                    template.menuOptionClass = ''
                                }
                                if (template.actionClass == undefined) {
                                    template.actionClass = ''
                                }
                                template.menuOptionClass =
                                    template.menuOptionClass +
                                    ' publish-disabled'
                                template.actionClass =
                                    template.actionClass + ' publish-disabled'
                            }
                            if (template.canRetire == false) {
                                if (template.menuOptionClass == undefined) {
                                    template.menuOptionClass = ''
                                }
                                if (template.actionClass == undefined) {
                                    template.actionClass = ''
                                }
                                template.menuOptionClass =
                                    template.menuOptionClass +
                                    ' retire-disabled'
                                template.actionClass =
                                    template.actionClass + ' retire-disabled'
                            }
                        }
                        templates.push(template)
                    })
                    return {
                        data: templates,
                        totalCount: res.result.totalCount,
                    }
                })
            )
    }
    globeltemplateGetAll(odataQuery: string) {
        odataQuery = odataQuery + '&IsGlobalTemplate=true'
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.REPORTS.TemplateLibraries}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    let templates: any[] = []
                    res.result.items.forEach((item: any) => {
                        let template = {
                            templateLibraryName: item.templateLibraryName,
                            name: item.form.name,
                            edition: item.form.edition,
                            status: item.form.status,
                            id: item.form.id,
                            formVersion: item.form.formVersion,
                            canDelete: item.canDelete,
                            canEdit: item.canEdit,
                            menuOptionClass: undefined as any,
                            dataClass: undefined as any,
                            actionClass: undefined as any,
                        }
                        if (
                            template.status &&
                            (template.status as string).trim().toLowerCase() ===
                                'published'
                        ) {
                            template.canDelete = false
                        }

                        if (
                            template.canDelete == false &&
                            template.canEdit == false
                        ) {
                            template.menuOptionClass =
                                ' delete-disabled publish-disabled '
                        } else {
                            if (template.canDelete == false) {
                                template.menuOptionClass = 'delete-disabled'
                            }
                            if (
                                template.status &&
                                (template.status as string)
                                    .trim()
                                    .toLowerCase() === 'published'
                            ) {
                                if (template.menuOptionClass == undefined) {
                                    template.menuOptionClass = ''
                                }
                                template.menuOptionClass =
                                    template.menuOptionClass +
                                    ' publish-disabled'
                            }
                        }
                        templates.push(template)
                    })
                    return {
                        data: templates,
                        totalCount: res.result.totalCount,
                    }
                })
            )
    }
    pipelinesGetallList(filterQuery: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetAll}${filterQuery}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
    }
    pipelinesGetall(odataQuery: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetAll}${odataQuery}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
    }
    getPipelineForView(id: any) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.GetPipelineForView}?Id=${id}`
            )
            .pipe(
                map((res: any) => {
                    return res.result
                })
            )
    }
    exportPipeline(formId: string) {
        return this.http.get<FormVersion>(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.ExportPipeline}?id=${formId}`
        )
    }
    ImportPipeline(formData: FormData) {
        return this.http.post<any>(
            `${this.apiUrl}${END_POINTS.PIPELINES.Pipelines}${END_POINTS.PIPELINES_Endpoints.ImportPipeLine}`,
            formData
        )
    }
    GetAllTaskByDate(date: string): Observable<any> {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.REPORTS.AllTaskByDate}` + date
        )
    }

    GetAllTemplateLibrariesType(
        IncludeAdmin: boolean,
        SkipCount: number = 0,
        MaxResultCount: number = 100
    ): Observable<any> {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.REPORTS.TemplateLibrariesType}?IncludeAdmin=${IncludeAdmin}&SkipCount=${SkipCount}&MaxResultCount=${MaxResultCount}`
        )
    }

    getTimeLine(
        sessionid: string,
        extractedFileId: string,
        pageNumber: number
    ) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetBusinessEvents}?sessionId=${sessionid}&extractedFileId=${extractedFileId}&pageNumber=${pageNumber}`
        )
    }
    getTimeLineCase(id: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetBusinessEventsForCase}?caseId=${id}`
        )
    }
    getMapping(sessionid: string, pageNumber: number) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetKeyInformation}?sessionId=${sessionid}&pageNumber=${pageNumber}`
        )
    }

    getClassificationEmbs(sessionid: string, pageNumber: number) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetClassificationEmbeddings}?sessionId=${sessionid}&pageNumber=${pageNumber}`
        )
    }

    // , pageNo: number, fileId: string
    // &filePageNumber=${pageNo}&extractedFileId=${fileId}
    getFeaturesCount(taskId: string, pageNo: number, fileId: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetFeaturesCount}?taskId=${taskId}&filePageNumber=${pageNo}&extractedFileId=${fileId}`
        )
    }

    getSummary(sessionid: string, featureType: string) {
        return this.http
            .get(
                `${this.apiUrl}${END_POINTS.EXTRACTED_FEATURE.ExtractedFeature}${END_POINTS.EXTRACTED_FEATURE_EndPoint.GetAll}?FeatureFilter=${featureType}&SessionIDFilter=${sessionid}`
            )
            .pipe(
                map((data: any) => {
                    if (data.result.items.length > 0) {
                        let res = JSON.parse(
                            data.result.items[0].extractedFeature.dataJSON
                        )
                        return res.summary
                    } else {
                        return null
                    }
                })
            )
    }
    templateDelete(id: string | undefined) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.FORMS.Forms}${END_POINTS.FORMS_EndPoints.Delete}?Id=${id}`
        )
    }

    getIndividualTaskReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        taskQueueId: string | null | undefined,
        organizationUnitId: number | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS.Individual}` + "StartDate" + startDate + "&"+ "EndDate"+ endDate, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ = this.apiUrl + '/HumanTasks/GetIndividualTaskReport?'
        if (startDate === null)
            throw new Error("The parameter 'startDate' cannot be null.")
        else if (startDate !== undefined)
            url_ +=
                'StartDate=' +
                encodeURIComponent(startDate ? '' + startDate.toJSON() : '') +
                '&'
        if (endDate === null)
            throw new Error("The parameter 'endDate' cannot be null.")
        else if (endDate !== undefined)
            url_ +=
                'EndDate=' +
                encodeURIComponent(endDate ? '' + endDate.toJSON() : '') +
                '&'
        if (taskQueueId !== undefined && taskQueueId !== null)
            url_ += 'TaskQueueId=' + encodeURIComponent('' + taskQueueId) + '&'
        if (organizationUnitId === null)
            throw new Error(
                "The parameter 'organizationUnitId' cannot be null."
            )
        else if (organizationUnitId !== undefined)
            url_ +=
                'OrganizationUnitId=' +
                encodeURIComponent('' + organizationUnitId) +
                '&'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }

    templatesReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        taskQueueId: string | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS.Template}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ =
            this.apiUrl + '/DocumentProcessingEngineService/TemplatesReport?'
        if (taskQueueId === null)
            throw new Error("The parameter 'taskQueueId' cannot be null.")
        else if (taskQueueId !== undefined)
            url_ += 'TaskQueueId=' + encodeURIComponent('' + taskQueueId) + '&'
        if (endDate === null)
            throw new Error("The parameter 'endDate' cannot be null.")
        else if (endDate !== undefined)
            url_ +=
                'endDate=' +
                encodeURIComponent(endDate ? '' + endDate.toJSON() : '') +
                '&'
        if (startDate === null)
            throw new Error("The parameter 'startDate' cannot be null.")
        else if (startDate !== undefined)
            url_ +=
                'startDate=' +
                encodeURIComponent(startDate ? '' + startDate.toJSON() : '') +
                '&'
        url_ = url_.replace(/[?&]$/, '')

        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }

    getAverageTimeReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        taskQueueId: string | null | undefined,
        showAllTask: boolean | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS.AverageTime}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ = this.apiUrl + '/HumanTasks/GetAverageTimeReport?'
        if (startDate === null)
            throw new Error("The parameter 'startDate' cannot be null.")
        else if (startDate !== undefined)
            url_ +=
                'StartDate=' +
                encodeURIComponent(startDate ? '' + startDate.toJSON() : '') +
                '&'
        if (endDate === null)
            throw new Error("The parameter 'endDate' cannot be null.")
        else if (endDate !== undefined)
            url_ +=
                'EndDate=' +
                encodeURIComponent(endDate ? '' + endDate.toJSON() : '') +
                '&'
        if (taskQueueId !== undefined && taskQueueId !== null)
            url_ += 'TaskQueueId=' + encodeURIComponent('' + taskQueueId) + '&'
        if (showAllTask === null)
            throw new Error("The parameter 'showAllTask' cannot be null.")
        else if (showAllTask !== undefined)
            url_ += 'ShowAllTask=' + encodeURIComponent('' + showAllTask) + '&'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }
    exportAverageTimeReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        taskQueueId: string | null | undefined,
        showAllTask: boolean | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS_EXCEL.AverageTimeExport}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ = this.apiUrl + '/HumanTasks/ExportAverageTimeReport?'
        if (startDate === null)
            throw new Error("The parameter 'startDate' cannot be null.")
        else if (startDate !== undefined)
            url_ +=
                'StartDate=' +
                encodeURIComponent(startDate ? '' + startDate.toJSON() : '') +
                '&'
        if (endDate === null)
            throw new Error("The parameter 'endDate' cannot be null.")
        else if (endDate !== undefined)
            url_ +=
                'EndDate=' +
                encodeURIComponent(endDate ? '' + endDate.toJSON() : '') +
                '&'
        if (taskQueueId !== undefined && taskQueueId !== null)
            url_ += 'TaskQueueId=' + encodeURIComponent('' + taskQueueId) + '&'
        if (showAllTask === null)
            throw new Error("The parameter 'showAllTask' cannot be null.")
        else if (showAllTask !== undefined)
            url_ += 'ShowAllTask=' + encodeURIComponent('' + showAllTask) + '&'
        url_ = url_.replace(/[?&]$/, '')

        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }

    ExportIndividualTaskReport(body: any) {
        // return this.http.post(`${this.apiUrl}${END_POINTS.REPORTS_EXCEL.IndividualExport}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ = this.apiUrl + '/HumanTasks/ExportIndividualTaskReport'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.post<any>(url_, body).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }

    getTemplatesReportToExcel(
        taskQueueId: string | undefined,
        endDate: moment.Moment | undefined,
        startDate: moment.Moment | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS_EXCEL.TemplatesExcel}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ =
            this.apiUrl +
            '/DocumentProcessingEngineService/GetTemplatesReportToExcel?'
        if (taskQueueId === null)
            throw new Error("The parameter 'taskQueueId' cannot be null.")
        else if (taskQueueId !== undefined)
            url_ += 'TaskQueueId=' + encodeURIComponent('' + taskQueueId) + '&'
        if (endDate === null)
            throw new Error("The parameter 'endDate' cannot be null.")
        else if (endDate !== undefined)
            url_ +=
                'endDate=' +
                encodeURIComponent(endDate ? '' + endDate.toJSON() : '') +
                '&'
        if (startDate === null)
            throw new Error("The parameter 'startDate' cannot be null.")
        else if (startDate !== undefined)
            url_ +=
                'startDate=' +
                encodeURIComponent(startDate ? '' + startDate.toJSON() : '') +
                '&'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }
    getTemplateLibrariesToExcel(
        filter: string | null | undefined,
        nameFilter: string | null | undefined,
        descriptionFilter: string | null | undefined,
        maxExpiresOnFilter: moment.Moment | null | undefined,
        minExpiresOnFilter: moment.Moment | null | undefined,
        isOutOfTheBoxFilter: number | undefined,
        iconFilter: string | null | undefined,
        maxCostPerPageInCentsFilter: number | null | undefined,
        minCostPerPageInCentsFilter: number | null | undefined
    ) {
        // return this.http.get(`${this.apiUrl}${END_POINTS.REPORTS_EXCEL.TemplateLibrariesExcel}`, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
        let url_ =
            this.apiUrl + '/TemplateLibraries/GetTemplateLibrariesToExcel?'
        if (filter !== undefined && filter !== null)
            url_ += 'Filter=' + encodeURIComponent('' + filter) + '&'
        if (nameFilter !== undefined && nameFilter !== null)
            url_ += 'NameFilter=' + encodeURIComponent('' + nameFilter) + '&'
        if (descriptionFilter !== undefined && descriptionFilter !== null)
            url_ +=
                'DescriptionFilter=' +
                encodeURIComponent('' + descriptionFilter) +
                '&'
        if (maxExpiresOnFilter !== undefined && maxExpiresOnFilter !== null)
            url_ +=
                'MaxExpiresOnFilter=' +
                encodeURIComponent(
                    maxExpiresOnFilter ? '' + maxExpiresOnFilter.toJSON() : ''
                ) +
                '&'
        if (minExpiresOnFilter !== undefined && minExpiresOnFilter !== null)
            url_ +=
                'MinExpiresOnFilter=' +
                encodeURIComponent(
                    minExpiresOnFilter ? '' + minExpiresOnFilter.toJSON() : ''
                ) +
                '&'
        if (isOutOfTheBoxFilter === null)
            throw new Error(
                "The parameter 'isOutOfTheBoxFilter' cannot be null."
            )
        else if (isOutOfTheBoxFilter !== undefined)
            url_ +=
                'IsOutOfTheBoxFilter=' +
                encodeURIComponent('' + isOutOfTheBoxFilter) +
                '&'
        if (iconFilter !== undefined && iconFilter !== null)
            url_ += 'IconFilter=' + encodeURIComponent('' + iconFilter) + '&'
        if (
            maxCostPerPageInCentsFilter !== undefined &&
            maxCostPerPageInCentsFilter !== null
        )
            url_ +=
                'MaxCostPerPageInCentsFilter=' +
                encodeURIComponent('' + maxCostPerPageInCentsFilter) +
                '&'
        if (
            minCostPerPageInCentsFilter !== undefined &&
            minCostPerPageInCentsFilter !== null
        )
            url_ +=
                'MinCostPerPageInCentsFilter=' +
                encodeURIComponent('' + minCostPerPageInCentsFilter) +
                '&'
        url_ = url_.replace(/[?&]$/, '')
        return this.http.get<any>(url_).pipe(
            map((res) => {
                return this.returnResult(res)
            })
        )
    }

    // users
    getUsers(queryString: string, allSelectedPermissions: string[]) {
        let url = `ExtendAbpUsers/GetUsers`
        // if (allSelectedPermissions !== undefined && allSelectedPermissions !== null)
        // allSelectedPermissions && allSelectedPermissions.forEach(item => { url += "Permissions=" + encodeURIComponent("" + item) + "&"; });
        return this.http.get<any>(`${this.apiUrl}/${url}${queryString}`)
    }

    updateUserPermissions(body: any) {
        return this.http.put(`${this.apiUrl}/User/UpdateUserPermissions`, body)
    }

    getAllRoles(queryString: string, allSelectedPermissions: string[]) {
        let url = `Role/GetRoles?`
        if (
            allSelectedPermissions !== undefined &&
            allSelectedPermissions !== null
        )
            allSelectedPermissions &&
                allSelectedPermissions.forEach((item) => {
                    url += 'Permissions=' + encodeURIComponent('' + item) + '&'
                })

        return this.http.get<any>(`${this.apiUrl}/${url}${queryString}`)
    }

    updateRole(body: any) {
        return this.http.post(`${this.apiUrl}/Role/CreateOrUpdateRole`, body)
    }

    getAllHistory(
        entityTypeFullName?: string | null | undefined,
        entityId?: string | null | undefined
    ) {
        let url = `AuditLog/GetEntityTypeChanges?`
        return this.http.get<any>(`${this.apiUrl}/${url}`)
    }

    exportUsersToExcel(queryString: string = '') {
        return this.http.get<any>(
            `${this.apiUrl}/User/GetUsersToExcel${queryString}`
        )
    }

    getRoles() {
        return this.http.get<any>(`${this.apiUrl}${'/Role/GetRoles?'}`)
    }

    getPermissions() {
        return this.http.get<any>(
            `${this.apiUrl}${'/Permission/GetAllPermissions'}`
        )
    }
    GetUserPermissionsForEdit(id: any) {
        return this.http.get<any>(
            `${this.apiUrl}${'/User/GetUserPermissionsForEdit'}?Id=${id}`
        )
    }

    getAuditLogs(queryString: string): Observable<any> {
        let url_ = this.apiUrl + '/AuditLog/GetAuditLogs' + queryString
        return this.http.get(url_)
    }

    setCaseTypeId(id: string) {
        this.caseTypeId = id
    }

    getCaseTypeId() {
        return this.caseTypeId
    }

    getAllActions(querystring: any) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EventActions.EventActions}${END_POINTS.EventActions_EndPoints.GetAll}${querystring}`
        )
    }
    createOrEditActions(data?: any): Observable<any> {
        return this.http.post(
            `${this.apiUrl}${END_POINTS.EventActions.EventActions}${END_POINTS.EventActions_EndPoints.CreateOrEdit}`,
            data
        )
    }

    createJob(data?: any): Observable<any> {
        return this.http.post(`${this.apiCloudUrl}Jobs`, data)
    }

    deleteAction(id: string) {
        return this.http.delete(
            `${this.apiUrl}${END_POINTS.EventActions.EventActions}${END_POINTS.EventActions_EndPoints.Delete}?Id=${id}`
        )
    }
    getAction(id: string) {
        return this.http.get(
            `${this.apiUrl}${END_POINTS.EventActions.EventActions}${END_POINTS.EventActions_EndPoints.GetEventActionForView}?id=${id}`
        )
    }
    GetLookupsForRunBot(): Observable<any[]> {
        var agentList = this.http
            .get<any>(`${this.apiCloudUrl}Agents/getLookUp`)
            .pipe(
                map((res) => {
                    let list: any[] = []
                    if (res) {
                        res.forEach((item: any) => {
                            list.push({
                                agentId: item.agentId,
                                agentName: item.agentName,
                            })
                        })
                    }
                    return list
                })
            )
        var automationList = this.http
            .get<any>(`${this.apiCloudUrl}Automations/getLookup`)
            .pipe(
                map((res: any) => {
                    let list: any[] = []
                    if (res) {
                        res.forEach((item: any) => {
                            list.push({
                                //automationId: item.automationId,
                                automationName: item.automationName,
                                automationVerions: item.versionList,
                            })
                        })
                    }
                    return list
                })
            )
        return forkJoin([agentList, automationList])
    }

    GetAutomationParameters(automationId: any): Observable<any[]> {
        var automationParameters = this.http
            .get<any>(`${this.apiCloudUrl}/automations/view/${automationId}`)
            .pipe(
                map((res) => {
                    let list: any[] = []
                    if (res) {
                        res?.automationParameters?.forEach((item: any) => {
                            list.push({
                                automationId: item.automationId,
                                dataType: item.dataType,
                                value: item.value,
                                direction: item.direction,
                            })
                        })
                    }
                    return list
                })
            )
        return automationParameters
    }

    GetLookupsForCloudserverQueue(): Observable<any[]> {
        var cloudserverQueue = this.http
            .get<any>(`${this.apiCloudUrl}Queues/Lookup`)
            .pipe(
                map((res) => {
                    let list: any[] = []
                    if (res) {
                        res.queueName.forEach((item: any) => {
                            list.push({
                                id: item.queueId,
                                name: item.name,
                            })
                        })
                    }
                    return list
                })
            )

        return cloudserverQueue
    }
}
