
<div *ngIf="this.authService.loggedInUser.activeTenantKey !== undefined "class="container no-access">
  <img src="../../../../assets/icons/Group 36239 (1).svg">
  <h4 >Permission Denied</h4>
  <h6> You do not have required permissions, kindly contact admin of your organization.</h6>
</div>

<div *ngIf="this.authService.loggedInUser.activeTenantKey === undefined" class="container no-access">
  <img src="../../../../assets/icons/Group 36239 (1).svg">
  <h4 >Access Denied</h4>
  <h6> OpenBots DocumentAI requires you to have an Active Subscription.</h6>
</div>