<div class="col-md-12 condition-editor">
  <!-- <div class="row">
      <div class="col-md-1">&nbsp;</div>
      <div class="col-md-1 text-center">And/Or</div>
      <div class="col-md-4 text-center">Field *</div>
      <div class="col-md-1 text-center">Operator *</div>
      <div class="col-md-5 text-center">Value *</div>
  </div> -->
  <div class="formMargin" *ngFor="let group of expressionformArray['controls'];let i=index">
      <div [formGroup]="this.getFormGroup(group)">
        <div class="row toprow  " [ngClass]="{'eventNonePointer' : checklistStatus === 'Published' || checklistStatus !== 'In-Progress' }">
          <div class="col-md-4 noPadding text-center logicaloperator">
            <div class="labelAdd">
              <img [src]="plus" class="imgbtn imgadd" (click)="addCheckExpression(i)" /> <span
                style="font-size: 20px;">And/Or</span>
            </div>
            <div *ngIf="i!==0">
              <ng-select [disabled]="checklistStatus !== 'Created'" appendTo="body" class="form-controls" [id]="'logicaloperator'+i" [items]="logicalOperatorList"
                ngDefaultControl bindLabel="label" [clearable]="false" bindValue="value" placeholder="And/Or"
                formControlName="logicalOperator">
              </ng-select>
              <!-- <p-dropdown appendTo="body" [options]="logicalOperatorList" 
              formControlName="logicalOperator"
              optionLabel="label" bindValue="value"
              placeholder="And/Or" [showClear]="true" ></p-dropdown> -->
            </div>
          </div>
      
          <div class="col-md-4 tw0select">
          </div>
          <div class="col-md-4">
            <div class="labelRemove" *ngIf="expressionformArray['length']>1">
              <img [src]="minus" class="imgbtn imgminus" (click)="removecheckExpresssion(i,group)" />
            </div>
          </div>
        </div>
          <div class="row bottomrow " [ngClass]="{'eventNonePointer' : checklistStatus === 'Published'}">
            <div class="col-md-5 col-sm-12 col-xs-12">
              <div class="fxBorderMain" [ngClass]="{'tablescroll': group?.get('leftComponent')?.get('parameters')?.value?.length>0}"  *ngIf="group?.get('leftComponent')!==undefined">
                <table style="width: 100%; overflow:scroll;">
                  <app-jsonlogic  [componentLocation]="'left'" [fieldsList]="variables"
                      [form]="this.getFormGroup(group.get('leftComponent'))">
                  </app-jsonlogic>
                </table>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 noPadding">
              <div class="selectRelativeDiv">
                <p-dropdown [options]="filtered_operatorList" 
                formControlName="operator" [id]="'operator'+i"
                optionLabel="label" optionValue="value"  (onShow)="filterOperatorList(i)" [ngClass]="{errorborder: this.checkerror(group.get('operator'))}"
                placeholder="operator" [showClear]="true" ></p-dropdown>
                <!-- <ng-select appendTo="body" [ngClass]="{errorborder: this.checkerror(group.get('operator'))}"
                 class="form-controls" [id]="'operator'+i" [items]="filtered_operatorList"
                  bindLabel="label" [clearable]="false" bindValue="value" placeholder="operator" formControlName="operator">
                </ng-select> -->
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <div class="fxBorderMain"  [ngClass]="{'tablescroll': group?.get('rightComponent')?.get('parameters')?.value?.length>0}"  *ngIf="group.get('fieldtype')?.value!==null && group?.get('rightComponent')!==undefined">
                <table style="width: 100%; overflow:scroll;">
                 <app-jsonlogic #jsoneditor [componentLocation]="'right'" [fieldsList]="variables"
                     [form]="this.getFormGroup(group.get('rightComponent'))">
                 </app-jsonlogic>
               </table>
             </div>
            </div>
             
          </div>
      </div>
  </div>
</div>

<!-- <div class="row" style="margin-top: 20px;">
  <div class="col-9"></div>
  <div class="col-3 logic-actionbtn">
    <button id="cancel-checklistemp" (click)="cancelLogic()" class="cancelLogicBtn">Cancel</button>
    <button id="create-checklistemp" (click)="applyLogic()" type="submit" style="margin-right: 0;"
      class="saveLogicBtn btnRightMargin checklistItemaddBtn">
      <i class="fa fa-save"></i>
      <span>{{'Add'}}</span>
    </button>
  </div>
</div> -->