<!-- view dialog -->
<div class="LibraryDialog">
    <div class="closeicon">
        <i (click)="closePopup()" class="pi pi-times"
            style="font-size: 1.5rem; position: absolute; top: 22px; right: 22px; cursor: pointer;"></i>
    </div>
    <form [formGroup]="formGroup" novalidate (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
            <h4 class="modal-title">
                <span>{{type === 'edit' ? 'Edit' : 'Create'}} Library</span>
            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 noLeftPadding noRightPadding inputUpload inputBtmPadding inputHeight inputpaddingBtm">
                    <label for="Name">Name <span style="color: red"> *</span></label>
                    <input type="text" formControlName="name" id="Name" class="form-control" name="Name"
                         required />
                    <app-error-message [control]="getCtrl('name')" fieldName="Name"> </app-error-message>
                </div>
                <div style="padding-top: 10px;" class="col-md-12 calenderWidth noLeftPadding noRightPadding inputUpload inputBtmPadding inputHeight inputpaddingBtm">
                    <label for="name">Expires On</label>
                    <mat-form-field>
                        <input matInput  id="expiresOn"  [readonly]="true"  [matDatepicker]="expiresOn" formControlName="expiresOn" placeholder="Expires On">
                        <mat-datepicker-toggle matSuffix [for]="expiresOn"></mat-datepicker-toggle>
                        <mat-datepicker #expiresOn></mat-datepicker>
                      </mat-form-field>
                </div>
                <div style="padding-top: 10px;" class="col-md-12 noLeftPadding noRightPadding inputUpload inputBtmPadding inputHeight">
                    <label for="name">Description</label>
                    <textarea formControlName="description"  id="description" class="form-control" name="description"
                    ></textarea>
                </div>
    
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="cancelTransBtn" (click)="closePopup()">Cancel</button>
            <button type="submit" class="createBtn">
                <i class="fa fa-save iconPrimaryCLass"></i>
                <span>{{type === 'edit' ? 'Update' : 'Save'}}</span>
            </button>
        </div>
    </form>

    <!-- <div class="row">
        <div class="col-10"></div>
        <div class="col-2">
            <button type="button" pButton class="createBtn">
              Save</button>
        </div>
    </div> -->
</div>