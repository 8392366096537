import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Directive({
  selector: '[trimtext]'
})
export class TrimTextDirective {
  constructor(private el: ElementRef) { }
  @HostListener('blur', ['$event.target'])
  onBlur(input: HTMLInputElement) {
    const trimmedValue = input.value.trim(); 
    input.value = trimmedValue; 
    input.dispatchEvent(new Event('input')); 
  }
}
