<!--  -->
<div class="layout-wrapper" [ngClass]="containerClass" >
    <app-topbar *ngIf="authService.userDataAvailable"></app-topbar>
    <div class="layout-sidebar scrollbarDesign">
        <app-sidebar *ngIf="authService.loggedInUser.bill?.status != 'Blocked' && authService.userDataAvailable"></app-sidebar>
    </div>
    <div class="layout-main-container" id="app-layout">
        <div class="layout-main">
            <h2 class="topHeadOrg topHeadOrgBtnCs"> <span> <app-breadcrumb></app-breadcrumb></span></h2>
            <router-outlet *ngIf="authService.userDataAvailable"></router-outlet>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
    <!-- <app-config></app-config> -->    
</div>
