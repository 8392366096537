import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-json-output',
  templateUrl: './formly-json-output.component.html',
  styleUrls: ['./formly-json-output.component.scss']
})
export class FormlyJsonOutputComponent extends FieldType<FieldTypeConfig> implements OnInit {
  result:any = {};
  constructor() {
    super();
   }
  ngOnInit() {

    if(this.formControl.value){
        this.result = this.formControl.value
    }
  }
}
