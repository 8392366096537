import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { LoaderService } from './core/services/loader.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls:['../assets/layout/styles/layout/lighthouse.scss']
})
export class AppComponent implements OnInit,OnDestroy {
    spinner:boolean=true;
    subscribtions?: Subscription;
    constructor(public authService:AuthService,public loader:LoaderService,public cd:ChangeDetectorRef) { }
    ngOnInit() {
   // this.authService.setup_AND_Configure_OIDC();
//    this.authService.navigate_to_requested_url();
    this.subscribtions= this.loader.show_spinner.subscribe(res=>{
        this.spinner=res;
        this.cd.detectChanges();
    });
    }
    ngOnDestroy(): void {
        this.authService.unsubscribe_OIDC__Events();
        this.subscribtions?.unsubscribe();
    }
}
