import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AppMonitoringService } from '../../core/services/appMonitoring.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export class BaseComponent {
    public toastr: ToastrService;
    public monitoringService: AppMonitoringService;
    constructor(inject: Injector, pagename: string) {
        this.toastr = inject.get(ToastrService);
        this.monitoringService = inject.get(AppMonitoringService);
        this.monitoringService.logPageView(pagename, window.location.href);
    }
    public bsConfig: Partial<any> = {
        dateInputFormat: 'll',
        showWeekNumbers: false,
        containerClass: 'theme-dark-blue',
    };
    showErrorToaster(summary: string, detail: string) {
        this.toastr.error(detail, summary);
    }
    showInfoToaster(summary: string, detail: string) {
        this.toastr.info(detail, summary);
    }
    showSuccessToaster(summary: string, detail: string) {
        this.toastr.success(detail, summary);
    }
    showWarningToaster(summary: string, detail: string) {
        this.toastr.warning(detail, summary);
    }
    logger(key: any, message: any) {
        if (environment.isDev) {
            console.log(key, message);
        }
    }
    getQueryparams(router: Router, key: string) {
        let allparamsstring = router.url.toString().split('?')[1];
        let parrams: string[] = allparamsstring.split('&');
        let obj: any = {
            record: null,
            totalCount: null,
        };
        parrams.forEach((p) => {
            obj[p.split('=')[0]] = p.split('=')[1];
        });
        return obj[key];
    }
    getParam(acrouter: ActivatedRoute, key: string)
    {
            return acrouter.snapshot.params[key];
    }
    disableDateEntry(event: any, datepicker: any) {
        if (event.keyCode == 8) {
            datepicker.bsValue = null;
        }
        return false;
    }
    exportToExcel(data: any, fileName: string, type: string) {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        // Convert the tableData to a worksheet
        let worksheet: XLSX.WorkSheet
        if (type == 'list') {
            worksheet= XLSX.utils.aoa_to_sheet( data );
        } else {
            worksheet = XLSX.utils.json_to_sheet( data );
        }
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Generate the Excel file
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', });
        // Create a Blob from the Excel buffer
        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        // Download the Excel file using file-saver
        FileSaver.saveAs(blob, fileName);
    }
    ExportToText(text: string, filename: string): void {
        const element = document.createElement('a');
        element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
        );
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    getOdataQuery(object: any, event: LazyLoadEvent) {
        let query = '';
      
        Object.keys(object).forEach((key) => {
            if (object[key]) {
                if (query == '') {
                    if (object[key] instanceof moment) {
                        object[key] = object[key].toISOString();
                    }
                    query = key + '=' + object[key];
                } else {
                    if (object[key] instanceof moment) {
                        object[key] = object[key].toISOString();
                    }
                    query = query + '&' + key + '=' + object[key];
                }
            }else if(object[key] == 0) {
                if (query == '') {
                    if (object[key] instanceof moment) {
                        object[key] = object[key].toISOString();
                    }
                    query = key + '=' + object[key];
                } else {
                    if (object[key] instanceof moment) {
                        object[key] = object[key].toISOString();
                    }
                    query = query + '&' + key + '=' + object[key];
                } 
            }
        });
        if (event) {
            if (query == '') {
                query = query + 'SkipCount=' + event.first + '&';
                query = query + 'MaxResultCount=' + event.rows;
            } else {
                query = query + '&SkipCount=' + event.first + '&';
                query = query + 'MaxResultCount=' + event.rows;
            }
            if (event.sortField) {
                let sorting = +event.sortOrder == -1 ? 'desc' : 'asc';
                query = query + '&Sorting=' + event.sortField + '+' + sorting;
            }
        }
        return '?' + query;
    }

    fileToBlob(file:File,type:string)
    {
        return new Blob([file], { type: type });
    }
}
