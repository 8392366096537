import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-multi-schema-type',
  templateUrl: './multi-schema-type.component.html',
  styleUrls: ['./multi-schema-type.component.scss']
})
export class MultiSchemaTypeComponent extends FieldType {

}
