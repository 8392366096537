import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  @Input() deleteDialogVisible: boolean;
  @Input() confirmationMessage: string = "Are you sure you want to delete?";
  @Input() confirmationHeader: string = "Delete";
  @Output() yes = new EventEmitter<void>();
  @Output() No = new EventEmitter<void>();
  @Output() hide = new EventEmitter<void>();

  constructor(
  ) { }

  ngOnInit() {
  }
  confirmDelete() {
    this.yes.emit();
  }

  hideDeleteDialog() {
    this.No.emit();
  }

  onDialogHide() {
    this.hide.emit();
    this.deleteDialogVisible=false;
    // Handle dialog hide event if needed
  }
}
