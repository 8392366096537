import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as FileSaver from 'file-saver';
import { LighthouseService } from 'src/app/shared/services/lighthouse.service';

@Component({
  selector: 'app-formly-input',
  templateUrl:'./formly-input.component.html',
  styleUrls: ['./formly-input.component.css']
})
export class FormlyInputComponent extends FieldType<FieldTypeConfig> implements OnInit {
  selectedFile: any;
    image:any;
    public data: any;
    cType:string=undefined; // BY DEFAULT string input
   constructor(private sanitizer: DomSanitizer,public service:LighthouseService) {
    super();
  }
  ngOnInit() {
    if(this.props['additionalProperties']!=undefined)
    {
        let schema=(this.props['additionalProperties'] as any[])[0] as any;
        if(schema && schema.format)
        {
              this.cType=schema.format;
        }
        else if( schema && schema.media)
        {
            this.cType=schema.media['type'];
            if(this.cType==ControlType.image){
                let objectURL = 'data:image/png;base64,' + this.formControl.value;
                this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            }
            if(this.cType==ControlType.json)
            {
                if(typeof(this.formControl.value)!=='string')
                {
                    this.data=this.formControl.value;
                }
                else
                {
                    this.data=JSON.parse(this.formControl.value);
                }
            }
            if(this.cType==ControlType.zip){
                this.downloadFile(this.formControl.value);
            }
        }

    }
  }
  fileUpload(event:any) {
    this.selectedFile = event.target.files[0].name;
    console.log(this.selectedFile);
    if (event.target.files && event.target.files.length > 0) {
      var file = (event.target.files[0] as File);
      console.log(this.service.selectedFunction.functionVersion.playgroudUI );
      if(this.service.selectedFunction.functionVersion.playgroudUI!=="imageclassification"  
      && this.service.selectedFunction.functionVersion.playgroudUI!=="vin" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="A" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="blurplatefactory" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="Face" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="plate_recognicer" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="signature_extractor_base64" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="signature_match_base64" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="nutrition_label" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="omr_base64" 
      && this.service.selectedFunction.functionVersion.playgroudUI!=="omr" 

      )
      {
        // this is file
         this.formControl.setValue(file);
      }
      else
      {
        // this is base64
        var blob = new Blob([file], { type: file.type });
        var reader =new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend=()=>{
          this.formControl.setValue(reader.result.toString().split(',')[1] as string);
        }

    }
  }
}

downloadFile(base64ZipString:string): void {
    const zipBlob = this.convertBase64ToZipFile(base64ZipString,'file.zip');
    FileSaver.saveAs(zipBlob);
  }
  convertBase64ToZipFile(base64String: string, filename: string): File {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: 'application/zip' });
    return new File([blob], filename);
  }
  
}


export enum ControlType{
    json='application/json',
    image='image/png',
    zip='application/zip',
    file='file'
}
