// panel-wrapper.component.ts
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
  <ob-ng-tooltip [data]="props.label" [size]="12"></ob-ng-tooltip>
  <ng-container #fieldComponent></ng-container>`
})
export class PanelFieldWrapper extends FieldWrapper {
}