import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { MomentInput } from 'moment';

@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {
    transform(value: MomentInput, format: string, isLocale: boolean = false) {
        if (!value) {
            return '';
        }
        if (isLocale) {
            let date = new Date(value.toString()+'Z');
            return moment(date).format(format);
        }
        else {
            return moment(value).format(format);
        }
    }
}
