import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-organization-blocked',
  templateUrl: './organization-blocked.component.html',
  styleUrls: ['./organization-blocked.component.scss']
})

export class OrganizationBlockedComponent implements OnInit {

  constructor(public authService:AuthService) {     
  }

  ngOnInit() {
  }

}
