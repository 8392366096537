import { HttpClient } from '@angular/common/http';
import {  Injector } from '@angular/core';
import * as FileSaver from 'file-saver';
import {  Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
export class BaseService {
http: HttpClient;
constructor(inject: Injector) {  this.http = inject.get(HttpClient);}
get apiUrl(): string { return `${environment.API_URL}/${environment.VERSION_URL}`;}
get cloudapiUrl() { return `${environment.AUTH_ISSUER}/api/v1`;}
get doc_api() { return `${environment.API_Document}`}
 // Below  methods are Generic
 get(endpoint: string, options?:any): Observable<any> {
    return this.http.get(`${this.apiUrl}${endpoint}`, options);
  }
  get_withFullUrl(url: string, options?:any): Observable<any> {
    return this.http.get(url, options);
  }
  // Get Multiple  Under Construction
  put(endpoint: string, data:any, options?:any): Observable<any> {
    return this.http.put(`${this.apiUrl}${endpoint}`, data, options);
  }

  post(endpoint: string, data?:any, options?:any): Observable<any> {
    return this.http.post(`${this.apiUrl}${endpoint}`, data, options);
  }
  patch(endpoint: string, data:any, options?:any): Observable<any> {
    return this.http.patch(`${this.apiUrl}${endpoint}`, data, options);
  }
  delete(endpoint: string, options?:any): Observable<any> {
    return this.http.delete(`${this.apiUrl}${endpoint}`, options);
  }
  logger(key:any,message:any)
  {
    if(environment.isDev){
      console.log(key,message);
    }
  }
  returnResult(res :any) {
    return res.result;
}

downloadTempFile(file: any) {
  let url=environment.API_Document_Url+'/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;;
  this.http.post(url,null, {
    responseType: 'arraybuffer',
    observe: 'response',
  }).subscribe((result:any)=>{
    console.log(result);
    const blob = new Blob([result.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, file.fileName);
})}

  // end
}
